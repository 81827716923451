import { useRef } from "react";
import Attachments from "../attachments/Attachments";
import { toast } from "react-toastify";
const TicketAddAttachmentsPanel = ({ sumbitHandler, cancelHandler }) => {
    const attachmentsRef = useRef()
    const onClickAddButtonHandler = async () => {
        const attachments = attachmentsRef.current.getState();
        let size = 0;
        if (attachments.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < attachments.length; i++) {

                size += attachments[i].size
                formData.append('attachments[]', attachments[i]);
            }
            if(size <= 20 * 1000 * 1000){
                sumbitHandler(formData, attachments);
            }else{
                const notify = () => toast.info('Łączny rozmiar plików nie może przekraczać 20MB');
                notify();
            }

        } else {
            const notify = () => toast.info('Nie dodano żadnych załączników');
            notify();
        }
    }
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.5)]">
            <div className="relative p-4 w-full max-w-[35vw] max-h-[90vh] bg-white rounded-lg shadow-lg overflow-y-auto ">
                <button onClick={cancelHandler} type="button" className="absolute top-2.5 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900   rounded-lg p-1.5">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <div className="flex flex-row w-full justify-center mb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                    </svg>
                </div>
                <Attachments ref={attachmentsRef} />
                <div className="flex justify-center items-center space-x-4">
                    <button onClick={cancelHandler} type="button" className="px-9 py-3 text-sm font-medium hover:text-white text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-red-500 ">
                        Anuluj
                    </button>
                    <button onClick={onClickAddButtonHandler} type="submit" className="px-9 py-3 text-sm font-medium rounded-lg hover:text-white text-gray-500 bg-white border border-gray-200 hover:bg-emerald ">
                        Dodaj
                    </button>
                </div>
            </div>
        </div>
    );
}
export default TicketAddAttachmentsPanel