import axiosInstance from './axiosInstance';

import endpoints from './endpoints';
import { API_TOKEN } from '../settings/settings';

export const getCompanyInfo = async (companySlug) => {

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_TOKEN}`,
    };


    const url = endpoints.company.company + companySlug

    return await axiosInstance.get(url, { headers }).then((response) => response).catch((error) => {
        if (error.response) {
            
            throw new Response(error.response.statusText, { status: error.response.status });
        } else if (error.request) {
            
            throw new Response(error.message, { status: 503 });
        } else {
            throw new Response("Not Found", { status: 404 });
        }
    })

}


export const getCompanies = async () => {
    return await axiosInstance.get(endpoints.company.list).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {

            return error
        }
    })
}


export const creatCompany = async (company) => {

    return await axiosInstance.post(endpoints.company.create, company).then((response)=>{
        return response.data
    }).catch((error)=>{
        if(error.response){
            return error.response.data
        }else if(error.request){
            return error.request
        }else {
            return error              
     }})
} 


export const deleteCompany = async (uuid) => {

    const url = endpoints.company.delete + uuid;

    return await axiosInstance.delete(url).then((response)=>{
        return response.data
    }).catch((error)=>{
        if(error.response){
            return error.response.data
        }else if(error.request){
            return error.request
        }else {
            return error               
     }})
} 
export const updateCompany = async (uuid, company) => {

    const url = endpoints.company.update + uuid;
    const headers ={
       "Content-Type": "multipart/form-data"
    }

    return await axiosInstance.post(url, company, {headers}).then((response)=>{
        return response.data
    }).catch((error)=>{
        if(error.response){
            return error.response.data
        }else if(error.request){
            return error.request
        }else {
            return error      
     }})
} 

export const clientLogin = async (credentials) => {

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_TOKEN}`,
    };

    const url = endpoints.company.login;

    return await axiosInstance.post(url, credentials, {headers}).then((response)=>{
        return response
    }).catch((error)=>{
        if(error.response){
            return error.response
        }else if(error.request){
            return error.request
        }else {
            return error      
     }})
} 