import Button from "../Button";
import Input from "../Input";
import Panel from "../Panel";
import { useLocation, useNavigate, useLoaderData } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { deleteTicketNote, updateTicketNote } from "../../api/apiTickets";
import Textarea from "../Textarea";
import React from "react";
import { toast } from 'react-toastify';
import ConfirmDelete from "../ConfirmDelete";
import { validateNoteTitle, validateNoteDescription, allSigns255, allSignsText } from "../../services/validate";
const TicketNoteEdit = () => {
    const ticketUUID = useLoaderData()
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const note = state?.note;
    const noteContentTextareaRef = useRef(null);
    const noteTitleInputRef = useRef(null);
    useEffect(()=>{
        if(!state?.note){
            navigate(`/admin/tickets/${ticketUUID}/notes`, {replace:true})
        }
    },[state, navigate, ticketUUID])
    const toggleDeleteConfirmation = () => {
        if (showDeleteConfirmation) {
            setShowDeleteConfirmation(false)
        } else {
            setShowDeleteConfirmation(true)
        }
    }
    const updateNote = async (e) => {
        e.preventDefault();
        if (!noteTitleInputRef.current?.isValid()) return;
        else if (!noteContentTextareaRef.current?.isValid()) return;
        const data = {
            'title': noteTitleInputRef.current.getValue(),
            'content': noteContentTextareaRef.current.getValue()
        }
        if (noteTitleInputRef.current?.getValue() === state?.note?.title && noteContentTextareaRef.current?.getValue() === state?.note?.content) {
            const notify = () => toast.info('Nie wprowadzono żadnych zmian');
            notify()
            return
        }
        const response = await updateTicketNote(ticketUUID, note.uuid, data)
        if (response.success) {
            const notify = () => toast.success('Notatka została zaktualizowana');
            notify()
            navigate(`/admin/tickets/${ticketUUID}/notes/edit`, {
                replace: true, state: {
                    note: {
                        title: noteTitleInputRef.current.getValue(),
                        content: noteContentTextareaRef.current.getValue(),
                        uuid: note.uuid
                    }
                }
            });
        }
    }
    const deleteNote = async (e) => {
        const response = await deleteTicketNote(ticketUUID, note.uuid);
        if (response.success) {
            const notify = () => toast.success('Notatka została usunięta');
            notify()
            navigate(`/admin/tickets/${ticketUUID}/notes`, { replace: true });
        }
    }
    return (
        <React.Fragment>
            {showDeleteConfirmation && <ConfirmDelete cancelDeleteHandler={toggleDeleteConfirmation} deleteHandler={deleteNote} deleteMessage={"Czy na pewno chcesz usunąć notatkę?"} />}
            <div>
                <h1 className="font-semibold m-12 ml-20 text-xl">Edycja notatki</h1>
                <Panel>
                    <form className="flex flex-col w-full gap-5 " onSubmit={updateNote} noValidate>
                        <Input
                            initialValue={state?.note?.title}
                            inValidMessage={'To pole jest obowiązkowe'}
                            name={'Tytuł'}
                            ref={noteTitleInputRef}
                            isRequired={true}
                            inputValidatingFunc={validateNoteTitle}
                            allowedSigns={allSigns255} />
                        <Textarea
                            initialValue={state?.note?.content}
                            name={'Treść'}
                            isRequired={true}
                            inValidMessage={'To pole jest obowiązkowe'}
                            ref={noteContentTextareaRef}
                            inputValidatingFunc={validateNoteDescription}
                            allowedSigns={allSignsText}
                        />
                        <div className="flex flex-row-reverse gap-5">
                            <Button value={'Zapisz'} width={'w-[10%]'} isActive={true} />
                            <Button value={'Usuń'} width={'w-[10%]'} type='button' clickHandler={toggleDeleteConfirmation} />
                        </div>
                    </form>
                </Panel>
            </div>
        </React.Fragment>
    );
}
export default TicketNoteEdit