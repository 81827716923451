import axiosInstance from './axiosInstance';

import endpoints from './endpoints';

export const getStatuses = async () => {

    return await axiosInstance.post(endpoints.statuses.list).then((response)=>{
        return response.data
    }).catch((error)=>{
       return 'undefined'
    })

};


export const getEmployees = async () => {

    return await axiosInstance.get(endpoints.employees.list).then((response)=>{
        return response.data
    }).catch((error)=>{
       return 'undefined'
    })

};