import axiosInstance from './axiosInstance';

import endpoints from './endpoints';
import { BASE_API_URL } from '../settings/settings';
import { API_TOKEN } from '../settings/settings';

export const deleteAttachment  = async (uuid)=>{

    const url = endpoints.attachments.delete + uuid;

    return await axiosInstance.delete(url , uuid).then((response)=>{
        return response.data
    }).catch((error)=>{
        if(error.response){
            return error.response.data
        }else if(error.request){
            return error.request
        }else {    
            return error;
     }})

}

export const sendAttachments = async (ticektUUID, attachments)=>{

    const headers = {
        "Content-Type": "multipart/form-data"
    }

    const url = endpoints.tickets.attachments + ticektUUID+'/attachments';

    return await axiosInstance.post(url , attachments, {headers}).then((response)=>{
        return response
    }).catch((error)=>{
        if(error.response){
            return error.response
        }else if(error.request){
            return error;
        }else {
            return error;
     }})

}


export const downloadAttachment = async (attachmentUUID)=>{

    const headers = {
        'Authorization': `Bearer ${API_TOKEN}`,
    };

    const url = BASE_API_URL + endpoints.attachments.download + attachmentUUID + '/download'

    return await axiosInstance.get(url, {headers: headers, responseType: 'blob',}).then((response)=>{
        return response
    }).catch((error)=>{
        if(error.response){
            return error.response
        }else if(error.request){
            return error;
        }else {
            return error;
     }})

}

export const previewAttachment = async (attachmentUUID)=>{

    const headers = {
        'Authorization': `Bearer ${API_TOKEN}`,
    };

    const url = BASE_API_URL + endpoints.attachments.download + attachmentUUID + '/preview'

    return await axiosInstance.get(url, {headers: headers, responseType: 'blob',}).then((response)=>{
        return response
    }).catch((error)=>{
        if(error.response){
            return error.response
        }else if(error.request){
            return error;
        }else {
            return error;
     }})

}