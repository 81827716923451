import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUserIpAddress } from '../api/apiUserInfo';

const initialState = {
  ipAddress: null,
  name: null,
  error: null,
  isLoading: false
}

export const getIpAddress = createAsyncThunk('userInfo/fetchIpAddress', async () => {
    const response = await getUserIpAddress()
    return response
  })

const userSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
      setUser(state, action) {
        state.user = action.payload;
      },
      setIpAddress(state, action) {
        state.ipAddress = action.payload
      },
      resetUser(state){
        state.user = {}
      }
    },
    extraReducers(builder) {
        builder.addCase(getIpAddress.fulfilled, (state, action) => {
          localStorage.setItem('userIpAddress', action.payload.ip);
          state.ipAddress = action.payload.ip
          state.isLoading = true
        })
        .addCase(getIpAddress.pending, (state) => {
          state.isLoading = true;
          state.error = '';
        }).addCase(getIpAddress.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        });
      }
})

export const {setIpAddress,setUser,resetUser} = userSlice.actions

export default userSlice.reducer;
