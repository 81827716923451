
import { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import Button from '../Button';

const Attachments = forwardRef(({name , allowedFileTypes, labelDescription, initalList, limitSize}, ref) => {

    const [attachments, setAttachmnts] = useState(initalList? initalList:[])
    const [errors, setErrors] = useState([]);


    useImperativeHandle(ref, () => ({
        getState: () => attachments,
    }));


    const ALLOWED_FILE_TYPES = allowedFileTypes ? allowedFileTypes: ['image/png', 'image/jpeg', 'image/svg+xml', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];

    const filesInputRef = useRef()

    const deleteFileFromAttachments = (e) => {
        const fileName = e.currentTarget.getAttribute('data-file');
        setAttachmnts((prevAttachments) =>
            prevAttachments.filter((attachment) => attachment.name !== fileName)
        );
        setErrors([])
    }

    const onClickSelectFileHandler = (e) => {
        e.preventDefault()
        setErrors([]);
        if(limitSize && attachments.length >= limitSize){
            setErrors((state)=> [...state, 'Maksymalna liczba plików: '+ limitSize]);
            return;
        }
        filesInputRef.current.click()
    }

    const onChangeHandler = (e) => {
        setErrors([])
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const isOkey = checkFile(file)
            if (isOkey && !attachments.some((item) => item.name === file.name)) {
                setAttachmnts((state) => [...state, file])
            }

        }

        filesInputRef.current.value = null;
    }

    const checkFile = (file) => {
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setErrors((state) => [...state, `${file.name} ma nie prawdiłowy format`]);
            return false
        }
        if (file.size > 5 * 1024 * 1024) {
            setErrors((state) => [...state, `${file.name} ma za duży rozmiar maksymalny dopuszcalny rozmiar to 5MB`]);
            return false
        }
        return true
    }

    return (
        <div className="flex flex-col ">
            <div className="flex flex-col gap-2 w-full justify-center text-left text-gray-500 w-full mb-5 max-h-[20%]">
                <label className="text-left text-ms ml-1 font-semibold">{name}<span className=' text-[0.8 em]'> {labelDescription? labelDescription : `(akctepowalne pliki: *.jpg *.jpge *.png *.pdf *.doc *.docx)`}</span></label>
                <input
                    type={'file'}
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                    multiple
                    ref={filesInputRef}
                />
                <Button type={'button'} width={'w-[20%]'} value={'Wybierz'} clickHandler={onClickSelectFileHandler} isActive={true} />
                <ol>
                    {errors.length > 0 ? errors.map((error, index) => {
                        return <li key={index}>
                            <p className="text-left text-red-500 text-ms ml-1 display-inline">{error}</p>
                        </li>;
                    }) : ''}
                </ol>
                <ol>
                    {attachments.length > 0 ? attachments.map((file, index) =>
                        <li key={index}>
                            <div className='flex flex-row gap-1'>
                                <p>{file.name} rozmiar: {formatSize(file)}
                                    <span>
                                        <button type="button" onClick={deleteFileFromAttachments} data-file={file.name}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 hover:text-red-500" >
                                                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </li>) : ''}
                </ol>
            </div>
        </div>
    );
})

export default Attachments




const formatSize = (file) => {
    if (file) {

        const fileSizeInBytes = file.size;
        const fileSizeInKilobytes = fileSizeInBytes / 1000;
        const fileSizeInMegabytes = fileSizeInKilobytes / 1000;

        if (fileSizeInMegabytes >= 1) {
            return fileSizeInMegabytes.toFixed(2) + ' MB'
        }

        if (fileSizeInKilobytes >= 1) {
            return fileSizeInKilobytes.toFixed(2) + ' KB'
        }

        return fileSizeInBytes + 'bajtów'
    }
}