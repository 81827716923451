import Panel from "./Panel";
import { Link } from "react-router-dom";

const ReportsList = (props) => {

    return (
        <div className="flex flex-col w-full">

            <h1 className="font-semibold m-12 ml-20 text-xl">Raporty</h1>
            <Panel>
                <ol className="w-full">
                    <li>
                        <div className="flex flex-row  py-2 pl-10 pr-6 w-full border border-1 border-slate-200 mb-5 rounded-lg justify-between items-center">
                            <p>Raport 1</p> 
                            <Link to='/admin/reports/new' className="py-2 px-4 bg-gray-200 rounded-xl hover:bg-gray-300">Wybierz</Link>
                        </div>
                    </li>
                </ol>
            </Panel>

        </div>
    );
}

export default ReportsList
