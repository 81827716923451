
import ContactUsForm from '../components/contact-us/ContactUsForm'
import {useState} from 'react';
import {createNewTicekt} from '../api/apiTickets';
import React from 'react';
import {useLoaderData} from 'react-router-dom';
import Button from '../components/Button';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

const ContactUs = () =>{

    const companyInfo = useLoaderData()

    const [createNewTicketStatus, setCreateNewTicketStatus] = useState({status: 'watingForSend'});
    const [reportReportingType, setReportReportingType] = useState({type: null});
        
    const sumbitFormHandler = async (data, attachments) =>{
        
        if(data !== 'undefined'){
            setCreateNewTicketStatus({status: 'send'})

            const response = await createNewTicekt(data);
                if(response.status === 200){
                    if(response.data.success){
                                          
                        setCreateNewTicketStatus({
                        status: 'success',
                        ticketNumber: response.data.data.number })
                       
                    }else{        
                        setCreateNewTicketStatus({status: 'failed'})        
                    }
                } else if (response.status === 413) {
                    const notify = () => toast.info('Łączny rozmiar plików nie może przekraczać 20MB');
                    notify();
                }
                else if (response.status === 422) {
                    const errors = response.data.errors;
                    const inValidIndexes = []
        
                    for (let key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            const index = key.split('.')[1];
                            inValidIndexes.push(index);
                        }
                    }
        
                    for (let index in inValidIndexes) {
                        const notify = () => toast.error('Wystąpił problem podczas przesyłania pliku ' + attachments[index].name)
                        notify()
                    }
                }else{
                    setCreateNewTicketStatus({status: 'failed'})
                } 
            }
    }


    const reportReportingTypeChoiceHandler = (e) =>{
        const choice = e.target.getAttribute('data-value');
        if(choice === 'anonymously'){
            setReportReportingType({type:'anonymously'})
        }else if(choice === 'manifestly'){
            setReportReportingType({type:'manifestly'})
        }
    }

    const showContactUsForm = createNewTicketStatus.status ==='watingForSend' || createNewTicketStatus.status ==='send' || createNewTicketStatus.status ==='failed'

    return(
        <React.Fragment>
        
        <div className='flex sm:flex-col flex-col w-full h-full items-center justify-center'> 

            <div className="w-full flex flex-col items-center mt-[3%] justify-center">
            <h1 className='text-6xl font-semibold text-center mb-10 '>Formularz zgłoszeniowy dla firmy {companyInfo.data.name}</h1>
            {reportReportingType.type === null &&
                <div className='flex flex-col items-center justify-center h-[75%] bg-gray-100 rounded-lg w-[40%] mt-[10%] p-10 gap-10'>
                    <h2 className='text-3xl font-semibold'>Wybierz forme zgłoszenia zdarzenia</h2>
                    <section className='w-[40%] flex flex-col gap-5'>
                        <Button value={'Zgłoś anonimowo'} isActive={true} type={'button'} clickHandler={reportReportingTypeChoiceHandler} customValue={'anonymously'}/>
                        <Button value={'Zgłoś personalnie '} isActive={true} type={'button'} clickHandler={reportReportingTypeChoiceHandler} customValue={'manifestly'}/>
                    </section>
                </div> }

                {(createNewTicketStatus.status === 'send') && <React.Fragment>
                        <div className='fixed top-0 right-0 left-0 z-50 w-full h-dvh flex flex-col justify-center text-center items-center bg-[rgba(0,0,0,0.5)]'>
                        <Loader message={"Wysyłanie zgłoszenia"}/>
                        </div>

                    </React.Fragment>}

                
                {(reportReportingType.type !== null && showContactUsForm) && <React.Fragment>
                        
                        <ContactUsForm sendData={sumbitFormHandler} sumbitStatus={createNewTicketStatus} reportingType={reportReportingType.type }/>
                    </React.Fragment>}

                {createNewTicketStatus.status ==='success' &&
                <div className='flex flex-col items-center justify-center h-[75%] bg-gray-100 rounded-lg w-[80%] mt-[10%] p-10 gap-5'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  className="size-20 fill-green-500">
                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                    </svg>

                    {reportReportingType.type === 'anonymously' &&<p>Twoje zgłoszenie {createNewTicketStatus.ticketNumber} została poprawnie zapisane i przesłane do podmiotu zewnętrznego, który rozpatrzy zgłoszenie. Dziękujemy.</p>}
                    {reportReportingType.type === 'manifestly' &&<p>Twoje zgłoszenie {createNewTicketStatus.ticketNumber} została poprawnie zapisane i przesłane do podmiotu zewnętrznego, który w ciągu 7 dni wróci do Ciebie z informacją zwrotną. Dziękujemy.</p>}
                </div> }
            </div>
        </div>
        </React.Fragment>
    );    
}

export default ContactUs



