import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import useCaptcha from 'use-offline-captcha';
import React from 'react';

const Captcha = forwardRef(({ownConf}, ref) => {

    const captchaRef = useRef(null)
    const inputRef = useRef(null)
    const [inputValue, setValue] = useState('')
    const [isValid, setValidity] = useState(null)
    const [showError, setShowError] = useState(null);
    const userOpt = {
        type: 'mixed', // "mixed"(default) | "numeric" | "alpha" 
        length: 5, // 4 to 8 number. default is 5
        sensitive: false, // Case sensitivity. default is false
        width: 200, // Canvas width. default is 200
        height: 50, // Canvas height. default is 50
        fontColor: '#000',
        background: 'rgba(255, 255, 255, .2)'
    }
    const { gen, validate } = useCaptcha(captchaRef, ownConf? ownConf : userOpt)


    const handleValidate = () => {
        const isValid = validate(inputValue)
        if (!isValid) {
            setValue('')
            setShowError(true)
            inputRef.current.focus()
        }
        setValidity(isValid)
        return isValid
    }

    const handleRefresh = () => {
        gen()
        setShowError(false)
    }

    useEffect(() => {
        if (gen) {

            gen()
        }
    }, [gen])


    useImperativeHandle(ref, () => ({
        isValid: handleValidate
    }))


    return (
        <React.Fragment>
            <div className='flex flex-row gap-3'>
                <div ref={captchaRef} />
                <input  className='border border-1 border-gray-300 pl-2 text-black' onChange={(e) => setValue(e.target.value)} value={inputValue} ref={inputRef} />
                <button className="px-4 py-2 bg-gray-400 rounded-lg text-white hover:bg-gray-500" onClick={handleRefresh} type="button">Odśwież kod</button>
            </div>
            {showError && <p className="text-red-500">Niestety. Wprowadzony kod nie zgadza się z obrazkiem. Jeśli chcesz skorzystać z innego obrazka, wybierz przycisk Odśwież kod.</p>}
        </React.Fragment>
    );
})

export default Captcha
