import { useLoaderData, Link } from 'react-router-dom';
import TableHeader from '../TableHeader'

const UsersPanel = (props) => {

    const users = useLoaderData()

    let tbodyContent;

    if (users !== "undefined") {
        if (users?.data?.length > 0) {
            tbodyContent = users.data.map((user, index) => {                
                return <tr key={index} className="text-center py-10 border-b border-gray-100 border-solid">
                    <td className="p-10">{user.name}</td>
                    <td className="p-10">{user.email}</td>
                    <td className="p-10">{user.status.label}</td>
                    <td>
                        <Link                
                            to={'/admin/settings/users/edit'}
                            state={{ user: user }}
                            className="py-3 px-5 bg-gray-200 rounded-xl hover:bg-gray-300"> Edytuj</Link>
                    </td>
                </tr>
            })
        } else {
            tbodyContent = <tr><td colSpan={4}> <p className="m-20 text-center text-xl">Brak wyników wyszukiwania</p></td></tr>
        }
    } else {
        tbodyContent = <tr><td colSpan={4}> <p className="m-20 text-center text-xl">Nie udało się pobrać listy opiekunów</p></td></tr>
    }

    return (
        <div>
            <section className='flex flex-row-reverse'>
                <Link 
                    to={'/admin/settings/users/new'}
                    className="py-2 px-3 bg-gray-200 rounded-xl hover:bg-gray-300"> Dodaj nowego opiekuna</Link>
            </section>
            <table className="w-full table-fixed text-sm ">
                <thead className="">
                    <tr className=" border-b border-gray-100 border-solid">
                        <TableHeader name={'Nazwa'} colName={'user_name'} />
                        <TableHeader name={'Adres email'} colName={'user_email'} />
                        <TableHeader name={'Status'} colName={'user_status_id'} />
                    </tr>
                </thead>
                <tbody>
                    {tbodyContent}
                </tbody>
            </table>
        </div>
    );
}

export default UsersPanel
