import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import ticketStatusesReducer from './ticketStatusesSlice'
import employeesReducer from './employessSlice'
import userInfoReducer from './userSlice'

const reHydrateStore = () => {
  const storedIpAddress = localStorage.getItem('userIpAddress');
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  return {
      userInfo: {
        ipAddress: storedIpAddress,
        user: JSON.parse(user)
      },
      auth: {
        token: token
      }
    };
};


export const store = configureStore({
  reducer: {
    auth: authSlice,
    ticketStatuses: ticketStatusesReducer,
    employees: employeesReducer,
    userInfo: userInfoReducer
  },
  preloadedState: reHydrateStore()
})



