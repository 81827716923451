import Card from "../components/Card";
import LoginForm from "../components/LoginForm";
import { clientLogin } from "../api/apiCompany";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { logInClient } from '../store/authSlice';


const ClientLoginPage = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(state => state.auth.status);


    useEffect(() => {
        if (authStatus === 'sessionExpired') {
            const notify = () => toast.error('Twoja sesja wygasła');
            notify();
        }
    }, [authStatus])


    const loginUserHandler = async ({ credentials }) => {

        if (credentials) {
            const response = await clientLogin(credentials)
            if (response.status === 200) {
                dispatch(logInClient())
                const slug = response.data.data.slug
                navigate('/'+slug+'/ticket', {
                    state: {
                        redirect: true
                    }
                });
            } else {
                const notify = () => toast.error(response?.data.message ?? 'Nie udało się zalogować, błędny login lub hasło');
                notify()
            }
        } else {
            const notify = () => toast.error('Nie udało się zalogować');
            notify()
        }
    }

    return (
        <div className='h-screen flex justify-center items-center w-full'>
            <Card width={'w-[28%]'}>
                <h1 className="text-left text-gray-800 text-2xl font-bold ">Nowe zgłoszenie</h1>
                <p className="text-gray-500 text-left text-xs font-semibold">W celu dodania zgłoszenia zaloguj się</p>
                <LoginForm sumbitHandler={loginUserHandler} isInputName captcha />
            </Card>
        </div>
    );
}

export default ClientLoginPage;