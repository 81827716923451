import { useOutletContext } from "react-router-dom";
import { deleteAttachment } from "../../api/apiAttachments";
import { toast } from "react-toastify";
import React, { useState } from 'react';
import ConfirmDelete from '../ConfirmDelete';
import TicketAddAttachmentsPanel from './TicketAddAttachmentsPanel';
import { sendAttachments, downloadAttachment } from "../../api/apiAttachments";
import { getTicketAttachments } from '../../api/apiTickets';
import { useEffect } from "react";
import { BASE_API_URL } from "../../settings/settings";
import endpoints from "../../api/endpoints";

const TicketAttachmentsPanel = () => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showAddAttachmentPanel, setShowAddAttachmentPanel] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [uuidForDelete, setUuidForDelete] = useState(null);
    const [attachmentsList, setattachmentsList] = useState([]);
    const [reload, setReload] = useState(false);
    const outletContext = useOutletContext();
    const deleteAttachemntHandler = async (e) => {
        e.preventDefault()
        const response = await deleteAttachment(uuidForDelete);
        if (response.success) {
            const attachment = attachmentsList.find((attachment) => {
                return attachment.uuid === uuidForDelete
            })
            const notify = () => toast.success(`Załącznik ${attachment.name} został usunięty`);
            notify();
            setattachmentsList((prevAttachment) => {
                return prevAttachment.filter((attachment) => attachment.uuid !== uuidForDelete);
            })
        } else {
            const notify = () => toast.error();
            notify('Usunięcie załącznika nie powiodło się');
        }
        setDeleteMessage(false)
        setUuidForDelete(false)
        setShowDeleteConfirmation(false)
    }
    const toggleDeleteConfirmation = (e) => {
        const attachmentUuid = e.currentTarget.getAttribute('data-uuid');
        if (showDeleteConfirmation) {
            setDeleteMessage(null)
            setUuidForDelete(null)
            setShowDeleteConfirmation(false)
        } else {
            const attachment = attachmentsList.find((attachment) => {
                return attachment.uuid === attachmentUuid
            })
            setUuidForDelete(attachmentUuid)
            setDeleteMessage('Czy na pewno chcesz usunąć ' + attachment.name + '?')
            setShowDeleteConfirmation(true)
        }
    }
    const toggleAddAttachmentsPanel = (e) => {
        if (showAddAttachmentPanel) {
            setShowAddAttachmentPanel(false)
        } else {
            setShowAddAttachmentPanel(true)
        }
    }
    const addAttachments = async (formData, attachments) => {
        const response = await sendAttachments(outletContext.ticketUUID, formData);
        if (response.status === 200) {
            if (response.data.success) {
                const notify = () => toast.success('Załączniki zostały dodane');
                notify();
                setReload(true);
                setShowAddAttachmentPanel(false)
            }
        }
        else if (response.status === 413) {
            const notify = () => toast.info('Łączny rozmiar plików nie może przekraczać 20MB');
            notify();
        }
        else if (response.status === 422) {
            const errors = response.data.errors;
            const inValidIndexes = []
            for (let key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const index = key.split('.')[1];
                    inValidIndexes.push(index);
                }
            }
            for (let index in inValidIndexes) {
                const notify = () => toast.error('Wystąpił problem podczas przesyłania pliku ' + attachments[index].name)
                notify()
            }
        } else {
            const notify = () => toast.error('Wystąpił problem podczas przesyłania pliku/plików')
            notify()
        }
    }
    useEffect(() => {
        const loader = async () => {
            const ticketUUID = outletContext.ticketUUID
            const response = await getTicketAttachments(ticketUUID)
            if (response.success) {
                setattachmentsList(response.data.attachments)
            }
        }
        loader()
        if (setReload) {
            setReload(false)
        }
    }, [outletContext.ticketUUID, setReload, reload])
    const downloadAttachmentHandler = async (e) => {
        const uuid = e.currentTarget.getAttribute('data-uuid')
        if (uuid) {
            const response = await downloadAttachment(uuid)
            if (response.status === 200) {
                const content_disposition = response.headers['content-disposition'].split(';')
                const filename = content_disposition[1].split('=')[1];
                var _url = window.URL.createObjectURL(response.data, { type: 'application/octet-stream' });
                var link = window.document.createElement('a');
                link.href = _url            
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(_url);
            }
        }
    }
    return (
        <React.Fragment>
            {showAddAttachmentPanel && <TicketAddAttachmentsPanel cancelHandler={toggleAddAttachmentsPanel} sumbitHandler={addAttachments} />}
            {showDeleteConfirmation && <ConfirmDelete deleteHandler={deleteAttachemntHandler} cancelDeleteHandler={toggleDeleteConfirmation} deleteMessage={deleteMessage} />}
            <div className="w-full flex flex-col gap-5">
                <div className="flex flex-row-reverse">
                    <button onClick={toggleAddAttachmentsPanel} className="py-2 px-3 bg-gray-200 rounded-xl">Dodaj Załącznik</button>
                </div>
                <ol className=" w-full ">
                    {attachmentsList && attachmentsList.length > 0 ? (
                        attachmentsList.map((attachment, index) => (
                            <li key={index} >{
                                <div className="flex flex-row  py-2 pl-10 pr-6 w-full border border-1 border-slate-200 mb-5 rounded-lg justify-between items-center">
                                    <p>{attachment.name}</p>
                                    <div className="text-center flex flex-row items-center gap-7 ">
                                        {attachment.user && <p>{attachment.user.name}</p>}
                                        <section className="flex flex-row gap-2">
                                            <button data-uuid={attachment.uuid} onClick={downloadAttachmentHandler}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                                    <path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                            <a href={BASE_API_URL + endpoints.attachments.download + attachment.uuid + '/preview'} target="_blank" rel="noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                </svg>
                                            </a>
                                            {attachment.allowed.to_delete &&
                                                <button onClick={toggleDeleteConfirmation} data-uuid={attachment.uuid}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </button>}
                                        </section>
                                    </div>
                                </div>
                            }</li>
                        ))
                    ) : (
                        <li className="flex flex-row justify-center mb-5"><p>Brak załączników dla tego zgłoszenia.</p></li>
                    )}
                </ol>
            </div>
        </React.Fragment>
    );
}
export default TicketAttachmentsPanel;