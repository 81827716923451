import { useLocation, useRouteError } from "react-router-dom";

const ErrorPage = () => {

    const error = useRouteError();
    const location = useLocation();
    const { state } = location;

    return (
        <section className="bg-white  h-dvh">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 flex flex-col justify-center items-center h-dvh">
                {error &&
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 ">{error?.status}</h1>
                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">{error?.statusText}</p>
                        <p className="mb-4 text-lg font-light text-gray-500 ">{error.data}</p>
                    </div>
                }
                {state &&
                    <div className="mx-auto max-w-screen-sm text-center">
                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">{state?.message}</p>
                        {/* <p className="mb-4 text-lg font-light text-gray-500 ">{error.data}</p> */}
                    </div>
                }
            </div>
        </section>);

}




export default ErrorPage;