import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";
import { formatDate } from "../../services/date";
const TicketNotesPanel = () =>{
    const ticektNotes = useLoaderData();
    const notesList = ticektNotes?.data?.note ? ticektNotes?.data?.note : []
    return(
        <div className="w-full flex flex-col gap-5">
            <div className="flex flex-row-reverse">
                <Link to={'new'} className="py-2 px-3 bg-gray-200 rounded-xl hover:bg-gray-300">Dodaj Notatkę</Link>
            </div>
            <ol className=" w-full ">
                {notesList && notesList.length > 0 ? (
                    notesList.map((note, index) => (
                        <li key={index} >{
                            <div className="flex flex-row  py-2 pl-10 pr-6 w-full border border-1 border-slate-200 mb-5 rounded-lg justify-between items-center">
                                <p>{note.title}</p>
                                <div className="text-center flex flex-row items-center gap-7 ">
                                    {note.user && <p>{note.user.name}</p>}
                                    <p>{formatDate(note.created_at)}</p>

                                    <Link to={'edit'} state={{
                                        note:note
                                    }}
                                          className="py-2 px-3 bg-gray-200 rounded-xl hover:bg-gray-300">
                                        Edytuj
                                    </Link>
                                </div>
                            </div>
                        }</li>
                    ))
                ) : (
                    <li className="flex flex-row justify-center mb-5"><p>Brak notatek dla tego zgłoszenia.</p></li>
                )}
            </ol>
        </div>

    );

}
export default TicketNotesPanel