
import { API_TOKEN } from "../settings/settings"
import axiosInstance from "./axiosInstance"
import endpoints from "./endpoints"


export const getUsers = async () => {

    return await axiosInstance.get(endpoints.users.list).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })
}


export const creatUser = async (user) => {

    return await axiosInstance.post(endpoints.users.create, user).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })
}

export const deleteUser = async (uuid) => {

    const url = endpoints.users.delete + uuid

    return await axiosInstance.delete(url).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })
}

export const updateUser = async (uuid, data) => {

    const url = endpoints.users.delete + uuid

    return await axiosInstance.patch(url, data).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })
}


export const sendResetPasswordEmail = async (email) => {

    const headers = {
        'Authorization': `Bearer ${API_TOKEN}`
    }

    const url = endpoints.users.resetPasswordEmail

    return await axiosInstance.post(url, {'email': email},{headers}).then((response) => {
        return response
    }).catch((error) => {
        if (error.response) {
            return error.response
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })
}
export const resetUserPassword = async (data) => {

    const headers = {
        'Authorization': `Bearer ${API_TOKEN}`
    }

    const url = endpoints.users.resetPassword
    
    return await axiosInstance.post(url,data,{headers}).then((response) => {
        return response
    }).catch((error) => {
        if (error.response) {
            return error.response
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })
}


