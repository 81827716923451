const Button = ({value, width, isActive, type, clickHandler, customValue, keyDown}) =>{     
     
        return(
        
            <input onClickCapture={clickHandler}  type={type !== undefined ? type : "submit"} value={value} className={`bg-gray-400 text-white ${width ? width: 'w-full'} h-10 rounded-lg hover:bg-emerald  font-bold disabled:border-slate-100  ${!isActive ? 'hover:bg-red-500 ':''}`} data-value={customValue? customValue: null}/>
        );   
}

export default Button;


