import axiosInstance from './axiosInstance';
import endpoints from './endpoints';
import { API_TOKEN } from '../settings/settings';

export const getTicketsList = async (page, limit, user, status, query, sort_field, sort_direction, company) => {

    const url = endpoints.tickets.list + `?page=${page}&limit=${limit}${user != null ? `&filter_user=${user}` : ''}${company != null ? `&filter_company=${company}` : ''}${status != null ? `&filter_status=${status}` : ''}${query != null ? `&filter_query=${query}` : ''}${sort_field != null ? `&sort_field=${sort_field}` : ''}${sort_direction != null ? `&sort_direction=${sort_direction}` : ''}`
    return await axiosInstance.get(url).then((response) => {
        return response.data
    }).catch((error) => {
        return 'undefined'
    })
};

export const getTicketInfo = async (uuid) => {

    const url = endpoints.tickets.ticket + uuid

    return await axiosInstance.get(url).then((response) => {
        return response.data
    }).catch((error) => {
        return 'undefined'
    })
};

export const createNewTicekt = async (data) => {

    const headers = {
        'Authorization': `Bearer ${API_TOKEN}`,
        "Content-Type": "multipart/form-data"
    }

    return await axiosInstance.post(endpoints.tickets.create, data, { headers }).then((response) => {
        return response
    }).catch((error) => {
        if (error.response) {
            return error.response
        } else if (error.request) {
            return error
        } else {
            return error
        }
    })
};


export const patchTicketDetails = async (data, uuid) => {

    const url = endpoints.tickets.ticket + uuid

    return await axiosInstance.patch(url, data).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })
}


export const getTicketNotesList = async (uuid) => {

    const url = endpoints.tickets.ticket + uuid + '/notes'
    return await axiosInstance.get(url).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {

            return error
        }
    })

}
export const getTicketLogsList = async (uuid) => {

    const url = endpoints.tickets.ticket + uuid + '/logs'+'?logsable_type=App\Models\Ticket'
    return await axiosInstance.get(url).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {

            return error
        }
    })

}

export const createTicketNote = async (uuid, data) => {

    const url = endpoints.tickets.ticket + uuid + '/notes'
    return await axiosInstance.post(url, data).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {

            return error
        }
    })

}


export const deleteTicketNote = async (uuid, noteId) => {

    const url = endpoints.tickets.ticket + uuid + '/notes/' + noteId

    return await axiosInstance.delete(url).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })

}

export const updateTicketNote = async (uuid, noteId, data) => {

    const url = endpoints.tickets.ticket + uuid + '/notes/' + noteId

    return await axiosInstance.put(url, data).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })

}

export const getTicketAttachments = async (uuid) => {

    const url = endpoints.tickets.ticket + uuid + '/attachments'

    return await axiosInstance.get(url).then((response) => {
        return response.data
    }).catch((error) => {
        if (error.response) {
            return error.response.data
        } else if (error.request) {
            return error.request
        } else {
            return error
        }
    })


}