import { useLoaderData, Link } from 'react-router-dom';
import TableHeader from '../TableHeader'
import CopyToClipboard from '../CopyToClipboard';
const ComapniesPanel = (props) => {
    const comapnies = useLoaderData()
    let tbodyContent;
    if (comapnies !== "undefined") {
        if (comapnies?.data?.length > 0) {
            tbodyContent = comapnies.data.map((company, index) => {
                return <tr key={index} className="text-center py-10 border-b border-gray-100 border-solid">
                    <td className="p-10">{company.name}</td>
                    <td className="p-10 text-left">
                        <div className='flex flex-row items-center'>
                            <a href={company.slug_link} target='_blank' rel="noreferrer" className='hover:decoration-solid hover:underline' >{company.slug_link}</a>
                            <CopyToClipboard content={company.slug_link}/>
                        </div>
                    </td>
                    <td className="p-10">{company.status.label}</td>
                    <td>
                        <Link
                            to={'/admin/settings/companies/edit'}
                            state={{ company: company }}
                            className="py-3 px-5 bg-gray-200 rounded-xl hover:bg-gray-300"> Edytuj</Link>
                    </td>
                </tr>
            })
        } else {
            tbodyContent = <tr><td colSpan={4}> <p className="m-20 text-center text-xl">Brak wyników wyszukiwania</p></td></tr>
        }
    } else {
        tbodyContent = <tr><td colSpan={4}> <p className="m-20 text-center text-xl">Nie udało się pobrać listy firm</p></td></tr>
    }
    return (
        <div>
            <section className='flex flex-row-reverse'>
                <Link
                    to={'/admin/settings/companies/new'}
                    className="py-2 px-3 bg-gray-200 rounded-xl hover:bg-gray-300"> Dodaj nową firmę</Link>
            </section>
            <table className="w-full table-fixed text-sm ">
                <thead className="">
                <tr className=" border-b border-gray-100 border-solid">
                    <TableHeader name={'Nazwa'} colName={'company_name'} />
                    <TableHeader name={'Link firmy do formularza zgłoszeniowego'} colName={'company_slug'} />
                    <TableHeader name={'Status'} colName={'status_id'} />
                </tr>
                </thead>
                <tbody>
                {tbodyContent}
                </tbody>
            </table>
        </div>
    );
}
export default ComapniesPanel