import Card from "../components/Card";
import Input from "../components/Input";
import { newPasswordInValidMessage, validateNewPassword, allSigns255 } from "../services/validate";
import React, { useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import { toast } from "react-toastify";
import { resetUserPassword } from "../api/apiUsers";
import Loader from "../components/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ResetPasswordPage = (props) => {

    const navigate = useNavigate();
    let [searchParams] = useSearchParams();

    const token = searchParams.get('token');
    const email = searchParams.get('email');


    useEffect(() => {

        if (token === 'undefined' && email === 'undefined') {
            navigate('/error', {replace:true ,state: {meessage:'Coś poszło nie tak'}})
        }

    }, [token, email, navigate])



    const [requestStatus, setRequestStatus] = useState({ status: null });

    const newPasswordRef = useRef(null);
    const retryNewPasswordRef = useRef(null);

    const onSendResetPasswordHandler = async (e) => {
        e.preventDefault()

        if (!newPasswordRef.current.isValid()) return
        else if (!retryNewPasswordRef.current.isValid()) return


        if (newPasswordRef.current?.getValue() !== retryNewPasswordRef.current?.getValue()) {
            newPasswordRef.current?.reset();
            retryNewPasswordRef.current?.reset();
            const notify = () => toast.error('Hasła nie pasują do siebie.')
            notify();
            return;
        }


        setRequestStatus({ status: 'send' });

        const data ={
            token,
            email,
            password: newPasswordRef.current?.getValue(),
            password_confirmation: retryNewPasswordRef.current?.getValue()
        }

        const response = await resetUserPassword(data);

        if (response.status === 200) {
            if (response.data.success) {
                setRequestStatus({
                    status: 'success',
                    message: 'Twoje hasło zostało pomyślnie zresetowane. Możesz teraz zalogować się, używając nowego hasła.'
                });
            }

        }
        else if (response.status === 400) {
            const notify = () => toast.error("Link aktywacyjny jest już nieważny")
            notify()
            setRequestStatus({ status: null });
            return

        }
        else {
            const notify = () => toast.error('Coś poszło nie tak')
            notify()
            setRequestStatus({ status: null });
        }
    }

    return (
        <div className='h-screen flex justify-center items-center w-full'>

            {(requestStatus.status === 'send') && <React.Fragment>
                <div className='fixed top-0 right-0 left-0 z-50 w-full h-dvh flex flex-col justify-center text-center items-center bg-[rgba(0,0,0,0.5)]'>
                    <Loader message={"Wysyłanie zgłoszenia"} />
                </div>

            </React.Fragment>}

            <Card width={'w-[28%]'}>
                {(requestStatus.status === null || requestStatus.status === 'send') &&
                    <React.Fragment>
                        <h1 className="text-left text-gray-800 text-2xl font-bold ">Resetowanie hasła</h1>
                        <p className="text-gray-500 text-left text-xs font-semibold">Wprowadź nowe hasło do swojego konta.</p>
                        <Input
                            type={'password'}
                            name={'Nowe hasło'}
                            allowedSigns={allSigns255}
                            inputValidatingFunc={validateNewPassword}
                            inValidMessage={newPasswordInValidMessage}
                            isRequired={true}
                            ref={newPasswordRef} />

                        <Input
                            type={'password'}
                            name={'Powtórz nowe hasło'}
                            allowedSigns={allSigns255}
                            inputValidatingFunc={validateNewPassword}
                            inValidMessage={newPasswordInValidMessage}
                            isRequired={true}
                            ref={retryNewPasswordRef} />
                        <Button isActive={true} value={"Resetuj hasło"} type={'button'} clickHandler={onSendResetPasswordHandler} />
                        <Link to={'/admin/login'} className={'text-gray-600 text-left hover:underline'} >Zaloguj się</Link>
                    </React.Fragment>
                }

                {requestStatus.status === 'success' &&
                    <div className='flex flex-col items-center justify-center  bg-gray-100 rounded-lg p-10 gap-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="size-20 fill-green-500">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                        </svg>
                        <p className="text-gray-600 text-left">{requestStatus.message}</p>
                        <Link to={'/admin/login'} className={'w-full bg-gray-300 py-2 px-3'}>Zaloguj się</Link>
                    </div>
                }
            </Card>
        </div>
    );
}

export default ResetPasswordPage
