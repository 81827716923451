import { useState } from "react";
import SideNaviagtion from "../components/SideNaviagtion";
import TopNavigation from "../components/TopNavigation";
import { Outlet, useNavigation} from "react-router-dom";
import Loader from "../components/Loader";


const Admin = (props) =>{

    const [isMenuExtend, setMenuExtendStatus] = useState(false);
    const {state} = useNavigation();

    const navWidthChangeHandler = (e) => {
        if(!isMenuExtend){
           
            setMenuExtendStatus(true)
        }else{
            
            setMenuExtendStatus(false)
        }
    }

    return(
        <div className={`flex flex-column min-h-screen`}>

                <SideNaviagtion navWidthChangeHandler={navWidthChangeHandler} initBasis={'basis-[4%]'}/>    
                
            <div className="basis-[98%]">
                <TopNavigation basis="basis-9/12"/>
                {state === 'loading' ? <Loader/> : null}
                <Outlet />
            </div>
            
        </div>
    );    
}

export default Admin
