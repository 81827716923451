
import { Link } from "react-router-dom";

const TableDataRow = ({ticketNumber, title, companyName, date, status, uuid}) =>{
        
    return(
        <tr className="text-center py-10 border-b border-gray-100 border-solid">
            <td className="p-10">{ticketNumber}</td>
            <td>{companyName}</td>
            <td className="break-all">{title}</td>
            <td>{status}</td>
            <td>{date}</td>
            <td><Link to={`/admin/tickets/${uuid}`} className="py-3 px-5 bg-gray-200 rounded-xl hover:bg-gray-300">Szczegóły</Link></td>
        </tr>
    );    
}

export default TableDataRow
