import { useRef, useEffect } from "react";
import Button from "../Button";
import Input from "../Input";
import Panel from "../Panel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { creatUser } from "../../api/apiUsers";
import {emailInValidMessage,  validateEmail, emailInputAllowedSigns, validatePassword, validateName, allSigns255, passwordInValidMessage } from "../../services/validate";

const UsersNewPanel = (props) =>{
        
    const navigate = useNavigate();

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const newPasswordRef = useRef(null);
    const retryNewPasswordRef = useRef(null);

    useEffect(()=>{
        nameRef.current.focusInput();
    },[nameRef])

    const createUserHandler = async (e) => {
        e.preventDefault()
        if (!nameRef.current?.isValid()) return;
        else if (!emailRef.current?.isValid()) return;
        else if (!newPasswordRef.current?.isValid()) return;
        else if (!retryNewPasswordRef.current?.isValid()) return;

        if (newPasswordRef.current?.getValue() !== retryNewPasswordRef.current?.getValue()) {
            newPasswordRef.current?.reset();
            retryNewPasswordRef.current?.reset();
            const notify = () => toast.error('Hasła nie pasują do siebie.')
            notify();
            return;
        }

        const user = {
            'name': nameRef.current?.getValue(),
            'email': emailRef.current?.getValue() ,
            'password': newPasswordRef.current?.getValue()
        }

        const response = await creatUser(user);

        if (response.success) {
            const notify = () => toast.success('Użytkownik został dodany');
            notify()
            navigate(`/admin/settings/users/edit`, {
                replace: true, state: {                    
                    user: response.data
                }
            });
        } else {
            const notify = () => toast.error('Dodanie użytkownik nie udało się');
            notify()
        }

    }


    return (    
            <div>
                <h1 className="font-semibold m-12 ml-20 text-xl">Nowy opiekun</h1>
                <Panel>
                    <form className="w-full" onSubmit={createUserHandler} noValidate>
                        <div className="flex flex-col gap-5 w-[40%]">

                            <Input
                                name={'Nazwa'}            
                                inputValidatingFunc={validateName}
                                allowedSigns={allSigns255}
                                inValidMessage={"To pole jest obowiązkowe"}
                                isRequired={true}
                                ref={nameRef}
                                id={'name'}
                            />
                            <Input
                                name={'Adres email'}                                
                                inputValidatingFunc={validateEmail}
                                inValidMessage={emailInValidMessage}
                                allowedSigns={emailInputAllowedSigns}
                                isRequired={true}
                                ref={emailRef}
                                id={'emial'}
                            />

                            <Input
                                name={'Nowe hasło'}
                                type={'password'}
                                inputValidatingFunc={validatePassword}
                                inValidMessage={passwordInValidMessage}
                                allowedSigns={allSigns255}
                                ref={newPasswordRef}
                                isRequired={true}
                                id={'newPassword'}
                            />

                            <Input
                                name={'Powtórz nowe hasło'}
                                type={'password'}
                                inValidMessage={passwordInValidMessage}
                                inputValidatingFunc={validatePassword}
                                allowedSigns={allSigns255}
                                ref={retryNewPasswordRef}
                                isRequired={true}
                                id={'retryNewPassword'}
                            />

                        </div>

                        <div className="flex flex-row-reverse gap-10">
                            <Button value={"Zapisz"} width={'w-[10%]'} isActive={true} />                            
                        </div>
                    </form>
                </Panel>
            </div>
    );
}

export default UsersNewPanel
