import { useLoaderData } from "react-router-dom";
import { formatDate } from "../../services/date";
const TicketHistoryPanel = (props) => {
    const logs = useLoaderData();
    return (
        <div className="w-full">
            <ol className=" w-full ">
                {logs?.data?.logs && logs?.data?.logs?.length > 0 ? (
                    logs.data.logs.map((log, index) => [3, 4, 5, 6, 7, 8, 11 ,12].includes(log.type.id) ? (
                        <li key={index}>
                            <div className="flex flex-row  py-2 pl-10 pr-6 w-full border border-1 border-slate-200 mb-5 rounded-lg justify-between items-center">
                                <div className="flex flex-row gap-1">
                                    <p>{log.type.label}</p>
                                    <p> {log.label}</p>
                                </div>
                                <div className="text-center flex flex-row items-center gap-7 ">
                                    <p>{log.user.name ? log.user.name : ''}</p>
                                    <p>{formatDate(log.created_at)}</p>
                                </div>
                            </div>
                        </li>
                    ) : null)
                ) : (
                    <li className="flex flex-row justify-center mb-5"><p>Brak historii dla tego zgłoszenia.</p></li>
                )}
            </ol>
        </div>
    );
}
export default TicketHistoryPanel