import { useState } from "react";

const useInput = (inputValidation, regexPattern, required, defaultValue) => {


    const [entredValue, setEntredValue] = useState(defaultValue ? defaultValue : '');
    const [entredValueTouched, setEntredValueTouched] = useState(false);

    const entredValueIsValid = inputValidation(entredValue) || (entredValue === '' && !required);

    const hasError = !entredValueIsValid && entredValueTouched;

    const inputChangeHandler = event => {    
        const regex = regexPattern
        if (event.target.value === '' || regex.test(event.target.value)) {
            setEntredValue(event.target.value);
        }
    }

    const focusInputHandler = () => {
        setEntredValueTouched(true);
    }

    const reset = () =>{
        setEntredValue('');
        setEntredValueTouched(false);
    }

    const setValue =(value)=>{
            setEntredValue(value);
    }
    return({       
         entredValue,
         entredValueIsValid,
         hasError,
         inputChangeHandler,
         focusInputHandler,
         entredValueTouched,
         reset,
         setValue
    })
};


export default useInput;