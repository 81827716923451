import { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import Select from "./Select";
import Input from "./Input";

const DateSelect = forwardRef(({ isRequired, hideModeSelect, mode, title }, ref) => {
    const [selectedDateOption, setSelectedDateOption] = useState(mode ? mode : '0')

    const validDate = /^[\d]{1,4}-[\d]{1,2}-[\d]{1,2}$/

    const dateRef = useRef(null);
    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);
    const selectRef = useRef(null);

    const [fromDateValue, setFromDateValue] = useState('');
    const [toDateValue, setToDateValue] = useState('');
    const [dateValue, setDateValue] = useState('');
    const [isValid, setIsValid] = useState(false);

    const onDateSelectOptionChange = ({ selectedValue }) => {
        setSelectedDateOption((prev) => {
            if (prev === '2') {
                toDateRef.current?.reset();
                fromDateRef.current?.reset();
            }
            if (prev === '1') {
                dateRef.current?.reset()
            }
            return selectedValue
        })

    }

    const handlerFromDate = (value) => {
        setFromDateValue(value)
    }

    const handlerToDate = (value) => {
        setToDateValue(value)
    }

    const handlerDate = (value) => {
        setDateValue(value)
    }

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const maxDate = `${year}-${month}-${day}`;

    useEffect(() => {



        if (selectedDateOption === '2') {
            if (fromDateValue !== '' && toDateValue !== '') {
                if (fromDateValue < toDateValue) {
                    toDateRef.current.hideError();
                    fromDateRef.current.hideError();
                } else {
                    toDateRef.current.showError();
                    fromDateRef.current.showError()
                }
            }
        }

        if (selectedDateOption === '0') {
            toDateRef.current?.reset();
            fromDateRef.current?.reset();
        }

        if (selectedDateOption === '2') {

            if (fromDateValue !== '' && toDateValue !== '') {
                if (fromDateValue < toDateValue) {
                    setIsValid(true)
                } else {
                    setIsValid(false)
                }
            } else {
                setIsValid(false)
            }

        } else if (selectedDateOption === '1') {
            if (dateValue !== '') {
                setIsValid(true)
            } else {
                setIsValid(false)
            }
        }
        else if (selectedDateOption === '0') {
            if (isRequired) setIsValid(false);
            else setIsValid(true);
        }
    }

        , [fromDateValue, toDateValue, setIsValid, isValid, selectedDateOption, dateValue, isRequired]);


    useImperativeHandle(ref, () => ({
        isValid: () => {
            if (isValid) {
                return true;
            } else {
                if (selectedDateOption === '2') {
                    if (!fromDateRef.current.isValid()) {
                        return false
                    }
                    if (!toDateRef.current.isValid()) {
                        return false
                    }
                } else if (selectedDateOption === '1') {
                    if (!dateRef.current.isValid()) {
                        return false
                    }
                }
                else {
                    selectRef.current.focus()
                }
            }
        },
        getSelectedDateOption: () => selectedDateOption,
        focusDateInput: () => { dateRef.current?.getInputRef()?.current?.focus() },
        focusFromDateInput: () => { fromDateRef.current?.getInputRef()?.current?.focus() },
        focusToDateInput: () => { toDateRef.current?.getInputRef()?.current?.focus() },
        getValue: () => {
            if (selectedDateOption === '1') {
                return {
                    fromDate: dateRef.current.getValue(),
                    toDate: dateRef.current.getValue()
                }
            } else if (selectedDateOption === '2') {
                return {
                    fromDate: fromDateValue,
                    toDate: toDateValue
                }
            } else {
                return {
                    fromDate: '',
                    toDate: ''
                }
            }
        }
    }))


    return (
        <div>
            {title && <p className="text-left text-ms ml-1 font-semibold text-gray-600 mb-2">{title}</p>}
            <div className="flex flex-row gap-5 ">
                {!hideModeSelect &&
                    <div className="basis-[25%]">
                        <Select inValidMessage={'Pole jest wymagane'} name={'Data wystąpienia naruszenia'} data={[{ id: 1, name: 'Konkretna data' }, { id: 2, name: 'Przybliżony okres' }]} onSelectChange={onDateSelectOptionChange} selectedId={selectedDateOption} isRequired={isRequired} ref={selectRef} />
                    </div>
                }

                {selectedDateOption === '1' &&
                    <div className="basis-[75%]">
                        <Input
                            initialValue={dateValue}
                            id={'fromDate'}
                            type="date"
                            name={'Dzień'}
                            formName={'fromDate'}
                            isRequired={isRequired}

                            inValidMessage={"To pole jest obowiązkowe"}
                            ref={dateRef}
                            inputValidatingFunc={entredValue => validDate.test(entredValue)}
                            allowedSigns={validDate}
                            changeHandler={handlerDate}
                            maxDate={maxDate}
                        />
                    </ div>}

                {selectedDateOption === '2' &&
                    <div className="basis-[37.5%]">
                        <Input
                            maxDate={maxDate}
                            initialValue={fromDateValue}
                            ref={fromDateRef}
                            type="date"
                            name={'Od'}
                            isRequired={isRequired}

                            formName={'fromDate'}
                            inValidMessage={'To pole jest obowiązkowe. Proszę upewnić się, że data początkowa jest wcześniejsza niż data końcowa.'}
                            inputValidatingFunc={entredValue => {
                                if (validDate.test(entredValue)) {
                                    if (toDateRef.current?.getValue() !== '') {
                                        if (entredValue < toDateRef.current?.getValue()) {
                                            return true
                                        }
                                        return false
                                    }
                                    return true
                                }
                                return false
                            }
                            }
                            allowedSigns={validDate}
                            changeHandler={handlerFromDate}
                        />
                    </ div>}

                {selectedDateOption === '2' &&
                    <div className="basis-[37.5%]">
                        <Input
                            maxDate={maxDate}
                            initialValue={toDateValue}
                            ref={toDateRef}
                            formName={'toDate'}
                            type="date"
                            name={'Do'}
                            isRequired={isRequired}

                            inValidMessage={'To pole jest obowiązkowe. Proszę upewnić się, że data końcowa jest późniejsza niż data początkowa.'}
                            inputValidatingFunc={entredValue => {
                                if (validDate.test(entredValue)) {
                                    if (fromDateRef.current?.getValue() !== '') {
                                        if (entredValue > fromDateRef.current?.getValue()) {
                                            return true
                                        }
                                        else {
                                            return false
                                        }
                                    } else {
                                        return true
                                    }
                                }
                                return false
                            }}
                            allowedSigns={validDate}
                            changeHandler={handlerToDate}
                        />
                    </ div>}
            </div>
        </div>
    );
});

export default DateSelect
