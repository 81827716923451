import Panel from "./Panel";
import { Outlet } from "react-router-dom";
import TicketDetailsNavTab from "./tickets/TicketDetailsNavTab";



const SettingsPanel = () =>{
    return(
        <div>
            <h1 className="font-semibold m-12 ml-20 text-xl">Ustawienia</h1>
            <nav className="flex flex-row m-10 ml-20 gap-10">
                <TicketDetailsNavTab   to={`/admin/settings/users`} >Opiekunowie Zgłoszenia</TicketDetailsNavTab>
                <TicketDetailsNavTab   to={`/admin/settings/companies`} >Firmy</TicketDetailsNavTab>
             </nav>
            <Panel>                
                <Outlet />                            
            </Panel>
        </div>
    );  
}

export default SettingsPanel
