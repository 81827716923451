import { useLoaderData } from 'react-router-dom';
import StatCard from './StatCard';
const Dashboard = (props) => {

    const stats = useLoaderData();

    const companiesStats = stats?.data?.stats?.companies ? stats?.data?.stats?.companies : null;
    const ticketsStats = stats?.data?.stats?.tickets ? stats?.data?.stats?.tickets : null;



    return (
        <div className="w-full h-dvh flex flex-row flex-wrap content-start">
            {ticketsStats !== null &&
                <section className='flex flex-col gap-2 bg-gray-100 text-white flex-wrap rounded-lg px-5 py-5 m-5'>
                    <label className='text-gray-500 font-semibold text-2xl pl-5'>Zgłoszenia</label>
                    <div className={' flex flex-row flex-wrap'}>
                        <StatCard amount={ticketsStats.new} title={'Nowe'} bgColor={'bg-blue-500 '} />
                        <StatCard amount={ticketsStats.processing} title={'Przetwarzane'} bgColor={'bg-orange-500 '} />                        
                        <StatCard amount={ticketsStats.successful} title={'Zakończone'} bgColor={'bg-emerald '} />
                    </div>
                </section>
            }
            {companiesStats !== null &&
                <section className='flex flex-col gap-2 bg-gray-100 text-white flex-wrap rounded-lg px-5 py-5 m-5'>
                    <label className='text-gray-500 font-semibold text-2xl pl-5'>Firmy</label>
                    <div className={' flex flex-row '}>
                        <StatCard amount={companiesStats.active} title={'Aktywne'} bgColor={'bg-emerald'} />
                        <StatCard amount={companiesStats.inActive} title={'Nieaktywne'} bgColor={'bg-gray-500'} />
                    </div>
                </section>
            }
        </div>
    );
}

export default Dashboard
