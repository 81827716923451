import axiosInstance from './axiosInstance';

import endpoints from './endpoints';
import { API_TOKEN } from '../settings/settings';

export const loginUser = async (credentials) =>{

    const headers = {
        'Authorization': `Bearer ${API_TOKEN}`
    }; 

    const url = endpoints.auth.login

    return await axiosInstance.post(url, credentials, { headers }).then((response)=>{
        return response.data
    }).catch((error)=>{
        if(error.response){
            return error.response.data
        }else if(error.request){
            return error.request
        }else {
            return error    
     }})

}

