import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  token: null,
  status: null,
  clientLogIn: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state, action) => {
        state.isAuthenticated = true;
        state.token = action.payload;
        state.status = "logIn";
    },
    logOut: (state)=>{
      state.isAuthenticated = false;
      state.token = false;
      state.status = "logOut";
    },
    
    sessionExpired: (state)=>{
      state.isAuthenticated = false;
      state.token = false;
      state.status = "sessionExpired";
    },
    logInClient:(state)=>{
      state.clientLogIn = true;
    }
  },
})

export const {logIn, logOut, sessionExpired,logInClient } = authSlice.actions

export default authSlice.reducer