
const SearchBar = ({basis, changeHandler, blurHandler, value}) =>{
        
    return(
        <div className={` ${basis} flex flex-col justify-center gap-5 `}>
            <label className="font-semibold text-xl ml-2">Wyszukaj</label>
            <input value={value} onChange={changeHandler} onBlur={blurHandler} className="rounded-lg  py-2 pl-9 pr-3 w-full focus:border-emerald focus:outline-none border border-slate-300 disabled:placeholder:text-gray-700 disabled:shadow-none" type="text" placeholder="Wyszukaj" name="searchBar"/>
        </div>
    );    
}

export default SearchBar



