import Panel from "../Panel";
import TableDataRow from '../TableDataRow';
import Pagination from "../Pagination";
import {useEffect, useState} from 'react';
import { getTicketsList } from "../../api/apiTickets";
import {formatDate} from '../../services/date'
import TableControls from "../TableControls";
import TableHeader from "../TableHeader";
const TicketsList = () =>{
    const [ticketsList, setTicketsList] = useState("undefined")
    const [sortOfNumberCol, setSortOfNumberCol] =  useState("none")
    const [sortOfTitleCol, setSortOfTitleCol] =  useState("none")
    const [sortOfCompanyCol, setSortOfCompanyCol] =  useState("none")
    const [sortOfStatusCol, setSortOfStatusCol] =  useState("none")
    const [sortOfDateCol, setSortOfDateCol] =  useState("desc")
    const [filtersData, setfilterData] = useState({
        page: 1,
        limit: 5,
        filter_query: null,
        filter_user: null,
        filter_status: null,
        filter_company: null,
        sort_field: null,
        sort_direction: null
    })
    var tbodyContent;
    var metaOfTicketsList;
    useEffect(() =>{
        async function fetchTicketsList() {
            const response = await getTicketsList(filtersData.page,filtersData.limit,filtersData.filter_user, filtersData.filter_status, filtersData.filter_query, filtersData.sort_field, filtersData.sort_direction, filtersData.filter_company)
            if(response.success === true){
                setTicketsList(response)
            }
        }

        fetchTicketsList()

    }, [filtersData])
    const onSumbitSearchAndFiltersForm = (data) =>{
        setfilterData(prevState => ({
            ...prevState,
            filter_query: data.filter_query,
            filter_user: data.filter_user,
            filter_status: data.filter_status,
            filter_company: data.filter_company
        }));
    }
    const  onPageClikHandler = async (e) =>{
        setfilterData(prevState => ({
            ...prevState,
            page:e.target.value
        }));
    }
    const onControllButtonsClikHander = async (e) =>{
        e.preventDefault()
        const countPage= Math.ceil(ticketsList.meta.total /ticketsList.meta.limit)
        if(e.currentTarget.value === 'prev' && ticketsList.meta.page-1 > 0 ){
            setfilterData(prevState => ({
                ...prevState,
                page:ticketsList.meta.page-1
            }));
        }
        else if(e.currentTarget.value === 'next' && ticketsList.meta.page+1 <= countPage ){
            setfilterData(prevState => ({
                ...prevState,
                page:ticketsList.meta.page+1
            }));
        }
    }
    const onSortColControlClick = (columnName) => {
        const currentSort = {
            number: sortOfNumberCol,
            company_id: sortOfCompanyCol,
            title: sortOfTitleCol,
            status_id: sortOfStatusCol,
            created_at: sortOfDateCol
        };

        setSortOfNumberCol('none');
        setSortOfCompanyCol('none');
        setSortOfTitleCol('none');
        setSortOfStatusCol('none');
        setSortOfDateCol('none');

        let newSortDirection;
        if (currentSort[columnName] === 'none') {
            newSortDirection = 'desc';
        } else if (currentSort[columnName] === 'desc') {
            newSortDirection = 'asc';
        } else {
            newSortDirection = 'none';
        }
        switch(columnName) {
            case 'number':
                setSortOfNumberCol(newSortDirection);
                break;
            case 'company_id':
                setSortOfCompanyCol(newSortDirection);
                break;
            case 'title':
                setSortOfTitleCol(newSortDirection);
                break;
            case 'status_id':
                setSortOfStatusCol(newSortDirection);
                break;
            case 'created_at':
                setSortOfDateCol(newSortDirection);
                break;
            default:
                break;
        }

        // Aktualizacja danych filtrujących
        setfilterData(prevState => ({
            ...prevState,
            sort_field: newSortDirection === 'none' ? null : columnName,
            sort_direction: newSortDirection === 'none' ? null : newSortDirection
        }));
        if(newSortDirection === 'none'){
            setSortOfDateCol('desc')
        }
    }
    if(ticketsList !== "undefined") {
        metaOfTicketsList = ticketsList.meta;
        if(ticketsList.data.length > 0){
            tbodyContent =  ticketsList.data.map( (ticket) =>{

                const formatedDateTime = formatDate(ticket.created_at)

                return  <TableDataRow key={ticket.number} ticketNumber={ticket.number} title={ticket.title} date={formatedDateTime} status={ticket.status.label} companyName={ticket.company.name} uuid={ticket.uuid}/>
            })
        }else{
            tbodyContent = <tr><td colSpan={5}> <p className="m-20 text-center text-xl">Brak wyników wyszukiwania</p></td></tr>
        }
    }else{
        tbodyContent = <tr><td colSpan={5}> <p className="m-20 text-center text-xl">Nie udało się pobrać zgłoszeń</p></td></tr>
    }

    return(
        <div>
            <h1 className="font-semibold m-12 ml-20 text-xl">Lista zgłoszeń</h1>
            <Panel>
                <TableControls sumbitHandler={onSumbitSearchAndFiltersForm}/>
                <table className="w-full table-fixed text-sm ">
                    <thead className="">
                    <tr className=" border-b border-gray-100 border-solid">
                        <TableHeader name={'Numer zgłoszenia'} colName={'number'} sortColStatus={sortOfNumberCol} sortControlClickHandler={onSortColControlClick} controls={true}/>
                        <TableHeader name={'Nazwa firmy'}  colName={'company_id'} sortColStatus={sortOfCompanyCol} sortControlClickHandler={onSortColControlClick} controls={true}/>
                        <TableHeader name={'Tytuł zgłoszenia'} colName={'title'} sortColStatus={sortOfTitleCol} sortControlClickHandler={onSortColControlClick} controls={true}/>
                        <TableHeader name={'Status'} colName={'status_id'} sortColStatus={sortOfStatusCol} sortControlClickHandler={onSortColControlClick} controls={true}/>
                        <TableHeader name={'Data zgłoszenia'} colName={'created_at'} sortColStatus={sortOfDateCol} sortControlClickHandler={onSortColControlClick} controls={true}/>
                    </tr>
                    </thead>
                    <tbody>
                    {tbodyContent}
                    </tbody>
                </table>
                <Pagination meta={metaOfTicketsList} pageClikHandler={onPageClikHandler} controllButtonsClikHander={onControllButtonsClikHander}/>
            </Panel>
        </div>
    );
}
export default TicketsList;