
const TableHeader = ({name, sortControlClickHandler, sortColStatus, colName, controls}) =>{


    const onClickHandler = () =>{
        sortControlClickHandler(colName)
    }
        
    return(
        <th className="p-8">
            <div className={`flex flex-row items-center justify-center ${controls ? 'ml-5':''} gap-2`}>
                <p onClick={onClickHandler}>{name}</p>
                    {sortColStatus === 'none' &&
                    <button onClick={onClickHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                            <path fillRule="evenodd" d="M11.47 4.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1-1.06 1.06L12 6.31 8.78 9.53a.75.75 0 0 1-1.06-1.06l3.75-3.75Zm-3.75 9.75a.75.75 0 0 1 1.06 0L12 17.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0l-3.75-3.75a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                        </svg>
                    </button> }
                    {sortColStatus === 'desc' &&
                    <button onClick={onClickHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                            <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                        </svg>
                    </button> 
                    } 
                    {sortColStatus === 'asc' &&
                    <button onClick={onClickHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                            <path fillRule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clipRule="evenodd" />
                        </svg>
                    </button> 
                    } 
            </div>
        </th>
    );    
}

export default TableHeader
