const endpoints = {
    tickets: {
      ticket: '/tickets/',
      list: '/tickets',
      create:'/tickets',
      attachments: '/tickets/'
    },
    statuses:{
      list:'/statuses/tickets'
    },
    employees: {
      list: '/users'
    },
    company:{
      create:'/companies',
      company: '/companies/',
      list: '/companies',
      delete: '/companies/',
      update: '/companies/',
      login: '/companies/login'
    },
    auth:{
      login:'/users/login'
    },
    users:{
      list:'/users',
      create:'/users/register',
      delete: '/users/',
      update: '/users/',
      resetPasswordEmail: '/users/password/email',
      resetPassword: '/users/password/reset'
    },
    attachments:{
      download:'/attachments/',
      delete:'/attachments/',
      preview:'/attachments/',
      
    },
    dashobard:{
      stats: '/dashboard'
    },
    reports:{
      get: '/reports/1'
    }


    
  };
  
  export default endpoints;


