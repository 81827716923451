
export function formatDate (dateToformat, withHours = true){
    var formatedDateTime;
    if(dateToformat !== undefined){
        const date = new Date(dateToformat);
                const dateOptions = {  day: 'numeric',  month: 'short'};
                const timeOptions = {  hour: 'numeric',  minute: 'numeric'};
                const  formattedDate = date.toLocaleDateString('pl-Pl', dateOptions);
                const  formattedTime = date.toLocaleTimeString('pl-Pl', timeOptions);

                if (withHours) formatedDateTime = formattedDate + ', ' + date.getFullYear() + ' ' + formattedTime;
                else formatedDateTime = formattedDate + ', ' + date.getFullYear();
                
    }
    return formatedDateTime;
}