import { useEffect, useRef } from "react";
import Button from "../Button";
import Input from "../Input";
import Panel from "../Panel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { creatCompany } from "../../api/apiCompany";
import { validateCompanyName, validateCompanySlug, allSigns255 } from "../../services/validate";
const CompaniesNewPanel = (props) => {
    const navigate = useNavigate();
    const nameRef = useRef(null);
    const slugRef = useRef(null);
    useEffect(()=>{
        if(nameRef.current !== null){
            nameRef.current.focusInput();
        }
    },[nameRef])
    const createUserHandler = async (e) => {
        e.preventDefault()
        if (!nameRef.current?.isValid()) return;
        else if (!slugRef.current?.isValid()) return;
        const company = {
            'name': nameRef.current?.getValue(),
            'slug': slugRef.current?.getValue(),
        }
        const response = await creatCompany(company);
        if (response.success) {
            const notify = () => toast.success('Firma została dodana');
            notify()
            navigate(`/admin/settings/companies/edit`, {
                replace: true, state: {
                    company: response.data
                }
            });
        } else {
            const notify = () => toast.error('Dodanie firmy nie udało się');
            notify()
        }
    }
    return (
        <div>
            <h1 className="font-semibold m-12 ml-20 text-xl">Nowa firma</h1>
            <Panel>
                <form className="w-full" onSubmit={createUserHandler} noValidate>
                    <div className="flex flex-col gap-5 w-[40%]">
                        <Input
                            name={'Nazwa'}
                            inputValidatingFunc={validateCompanyName}
                            allowedSigns={allSigns255}
                            inValidMessage={"To pole jest obowiązkowe"}
                            isRequired={true}
                            ref={nameRef}
                        />

                        <Input
                            name={'Slag - skrócona nazwa która się pojawi w adresie url'}
                            inputValidatingFunc={validateCompanySlug}
                            inValidMessage={"To pole jest obowiązkowe"}
                            allowedSigns={allSigns255}
                            isRequired={true}
                            ref={slugRef}
                        />
                    </div>
                    <div className="flex flex-row-reverse gap-10">
                        <Button value={"Zapisz"} width={'w-[10%]'} isActive={true} />
                    </div>
                </form>
            </Panel>
        </div>
    );
}
export default CompaniesNewPanel