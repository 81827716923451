import axiosInstance from './axiosInstance';
import { API_TOKEN } from '../settings/settings';

const headers = {
    'Authorization': `Bearer ${API_TOKEN}`,
};

export const getReport = async (url) => {


    return await axiosInstance.get(url, {headers: headers, responseType: 'blob',}).then((response)=>{
        return response
    }).catch((error)=>{
       return 'undefined'
    })

};

