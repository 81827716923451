import React, { useState, useRef, useEffect } from "react";
import Button from "../Button";
import Input from "../Input";
import Panel from "../Panel";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDelete from "../ConfirmDelete";
import { emailInValidMessage, validateEmail, emailInputAllowedSigns, validateNewPassword, validateName, allSigns255, newPasswordInValidMessage } from "../../services/validate";
import { useSelector } from "react-redux";

import { deleteUser, updateUser } from "../../api/apiUsers";
import Select from '../Select';

const UsersEditPanel = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const currentUser = useSelector(state => state.userInfo.user);

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const newPasswordRef = useRef(null);
    const retryNewPasswordRef = useRef(null);
    const statusRef = useRef(null);

    useEffect(() => {
        if (!state?.user) {
            navigate("/admin/settings/users", { replace: true});
        }
    }, [state, navigate])


    const toggleDeleteConfirmation = () => {
        if (showDeleteConfirmation) {
            setShowDeleteConfirmation(false)
        } else {
            setShowDeleteConfirmation(true)
        }
    }

    const deleteUserHandler = async (e) => {
        e.preventDefault()
        const response = await deleteUser(state?.user?.uuid);

        if (response.success) {
            const notify = () => toast.success(`Użytkownik ${state?.user?.name} został usunięty`)
            notify()
            navigate('/admin/settings/users', { replace: true })
        } else {
            const notify = () => toast.error(response.message)
            notify()
        }
    }

    const updateUserHandler = async (e) => {
        e.preventDefault()
        if (!nameRef.current?.isValid()) return;
        else if (!emailRef.current?.isValid()) return;
        else if (statusRef.current.getValue() === '0' ) {
            const notify = () => toast.info('Użytkownik musi posiadać status')
            notify();
            return 
        }
        else if (!newPasswordRef.current?.isValid()) return;
        else if (!retryNewPasswordRef.current?.isValid()) return;

        if (newPasswordRef.current?.getValue() !== retryNewPasswordRef.current?.getValue()) {
            newPasswordRef.current?.reset();
            retryNewPasswordRef.current?.reset();
            const notify = () => toast.error('Hasła nie pasują do siebie.')
            notify();
            return;
        }


        if (nameRef.current?.getValue() === state?.user?.name && emailRef.current?.getValue() === state?.user?.email && newPasswordRef.current?.getValue() === '' && parseInt(statusRef.current.getValue()) === state?.user?.status?.id) {
            const notify = () => toast.info('Nie wprowadzono żadnych zmian');
            notify()
            newPasswordRef.current?.reset();
            retryNewPasswordRef.current?.reset();
            return
        }

        const data = {
            'name': nameRef.current?.getValue() === state?.user?.name ? null : nameRef.current?.getValue(),
            'email': emailRef.current?.getValue() === state?.user?.email ? null : emailRef.current?.getValue(),
            'password': newPasswordRef.current?.getValue() === '' ? null : newPasswordRef.current?.getValue(),
            'status_id' : parseInt(statusRef.current.getValue()) === state?.user?.status?.id ? null : statusRef.current.getValue()
        }


        const response = await updateUser(state?.user?.uuid, data)
        if (response.success) {
            const notify = () => toast.success(`Użytkownik ${state?.user?.name} został zaktualizowany`);
            notify()
            newPasswordRef.current.reset()
            retryNewPasswordRef.current.reset()
            navigate('/admin/settings/users/edit', {
                replace: true, state: {
                    user: response.data
                }
            })
        } else {
            const notify = () => toast.error("Wystąpił problem podczas aktulizacji użytkownika");
            notify()
        }
    }


    return (

        <React.Fragment>
            {showDeleteConfirmation && <ConfirmDelete cancelDeleteHandler={toggleDeleteConfirmation} deleteHandler={deleteUserHandler} deleteMessage={`Czy na pewno chcesz usunąć użytkownika ${state?.user?.name}`} />}
            <div>
                <h1 className="font-semibold m-12 ml-20 text-xl">Edycja opiekuna: {state?.user?.name}</h1>
                <Panel>
                    <form className="w-full" onSubmit={updateUserHandler} noValidate>
                        <div className="flex flex-col gap-5 w-[40%]">

                            <Input
                                initialValue={state?.user?.name}
                                name={'Nazwa'}
                                inputValidatingFunc={validateName}
                                allowedSigns={allSigns255}
                                inValidMessage={"To pole jest obowiązkowe"}
                                ref={nameRef}
                            />
                            <Input
                                initialValue={state?.user?.email}
                                name={'Adres email'}
                                inputValidatingFunc={validateEmail}
                                inValidMessage={emailInValidMessage}
                                allowedSigns={emailInputAllowedSigns}
                                ref={emailRef}
                            />

                            <Select data={[{id:1, label:'Aktywny'},{id:2, label:'Nieaktywny'} ]} type={'status'} ref={statusRef} selectedId={state?.user?.status?.id}/>

                            <Input
                                name={'Nowe hasło'}
                                type={'password'}
                                inputValidatingFunc={validateNewPassword}
                                inValidMessage={newPasswordInValidMessage}
                                allowedSigns={allSigns255}
                                ref={newPasswordRef}

                            />

                            <Input
                                name={'Powtórz nowe hasło'}
                                type={'password'}
                                inValidMessage={newPasswordInValidMessage}
                                inputValidatingFunc={validateNewPassword}
                                allowedSigns={allSigns255}
                                ref={retryNewPasswordRef}

                            />
                        </div>

                        <div className="flex flex-row-reverse gap-10">

                            <Button value={"Zapisz"} width={'w-[10%]'} isActive={true} />
                            {state?.user?.uuid !== currentUser.uuid ? <Button value={'Usuń'} width={'w-[10%]'} type='button' clickHandler={toggleDeleteConfirmation} /> : null}

                        </div>
                    </form>
                </Panel>
            </div>
        </React.Fragment>
    );
}

export default UsersEditPanel


