import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getStatuses } from '../api/apiEmployeesAndStatuses'

const initialState = {
  isLoading: false,
  data: [],
  error:''

}

export const fetchStatuses = createAsyncThunk('statuses/fetchData', async () => {
    const response = await getStatuses()
    return response
  })

const ticketStatusesSlice = createSlice({
    name: 'ticketStatuses',
    initialState,
    extraReducers(builder) {
        builder.addCase(fetchStatuses.fulfilled, (state, action) => {
          state.data = action.payload
          state.isLoading = true
        })
        .addCase(fetchStatuses.pending, (state) => {
          state.isLoading = true;
          state.error = '';
        }).addCase(fetchStatuses.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        });
      }
})


export default ticketStatusesSlice.reducer;
