import React, { useState } from "react";
import ConfirmDelete from "./ConfirmDelete";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetUser } from "../store/userSlice";
import { logOut } from "../store/authSlice";

const UserSubMenu = ({name, email}) => {

    const navigate = useNavigate();

    const [showSubMenu, setIfShowSubMenu] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const dispatch = useDispatch()

    const toggleDeleteConfirmation = () => {
        if (showDeleteConfirmation) {
            setShowDeleteConfirmation(false)
        } else {
            setShowDeleteConfirmation(true)
        }
    }

    const logOutHandler = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch(resetUser())
        dispatch(logOut())
        const notify = () => toast.success('Zostałeś wylogowany');
        notify();
        navigate('/admin/login', {replace: true});
    }

    const toogleSubMenu = () => {
        setIfShowSubMenu((prevState) => !prevState)
    }

    return (
        <React.Fragment>
            {showDeleteConfirmation && 
            <ConfirmDelete 
                cancelDeleteHandler={toggleDeleteConfirmation} 
                deleteHandler={logOutHandler} 
                deleteMessage={'Czy na pewno chcesz się wylogować'}
                svg={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-gray-400 w-11 h-11 mb-3.5 mx-auto size-6 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                    </svg>} 
                />}
            <div >
                <button onClick={toogleSubMenu}  type="button" className="text-white bg-slate-900 hover:bg-white hover:text-slate-900 rounded-full text-sm p-2 mr-3 text-center items-center hover:outline hover:outline-1 hover:outline-slate-900 " >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                </button>
                {showSubMenu && <div onMouseLeave={toogleSubMenu} className=" absolute z-10 bg-gray-100 divide-y divide-gray-100  rounded-lg shadow w-44 right-2 mt-1">
                    <div className="px-4 py-3 text-sm text-gray-900 ">
                        <div>{name}</div>
                        <div className="font-medium truncate">{email}</div>
                    </div>
                    <button onClick={toggleDeleteConfirmation} className="w-full block px-4 py-2 text-sm text-gray-700 hover:bg-white rounded-lg ">
                        Wyloguj się
                    </button>
                </div>}
            </div>
        </React.Fragment>
    );
}

export default UserSubMenu
