import React, { useRef, useState, useEffect } from "react";
import Button from "../Button";
import Input from "../Input";
import Panel from "../Panel";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateCompany, deleteCompany } from "../../api/apiCompany";
import Select from "../Select";
import ConfirmDelete from "../ConfirmDelete";
import {
    validateCompanyName,
    validateCompanySlug,
    allSigns255,
    validateCompanyPassword,
    companyPasswordRequiredInValidMessage,
    validateCompanyLogin,
    companyLoginRequiredInValidMessage
} from "../../services/validate";
import CopyToClipboard from "../CopyToClipboard";
import Attachments from "../attachments/Attachments";

const CompaniesEditPanel = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const { state } = location;

    const nameRef = useRef(null);
    const slugRef = useRef(null);
    const loginRef = useRef(null);
    const passwordRef = useRef(null);
    const attachmentsRef = useRef(null);
    const [selectedStatus, setSelectedStatus] = useState(state?.company?.status?.id);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    useEffect(() => {
        if (!state?.company) {
            navigate("/admin/settings/companies", { replace: true });
        }

    }, [state, navigate])

    const toggleDeleteConfirmation = () => {
        if (showDeleteConfirmation) {
            setShowDeleteConfirmation(false)
        } else {
            setShowDeleteConfirmation(true)
        }
    }
    const deleteCompanyHandler = async () => {
        const uuid = state?.company?.uuid;
        if (uuid) {
            const response = await deleteCompany(uuid)
            if (response?.success) {
                const notify = () => toast.success(`Firma ${state?.company?.name} została usunięta`);
                notify()
                setShowDeleteConfirmation(false)
                navigate('/admin/settings/companies', { replace: true })
            } else {
                const notify = () => toast.error(response?.message);
                notify()
            }
        } else {
            const notify = () => toast.error(`Coś poszło nie tak podczas usuwania firmy ${state?.company?.name}`);
            notify()
        }
    }
    const onSelectStatus = ({ selectedValue }) => {
        setSelectedStatus(selectedValue)
    }
    const updateCompanyHandler = async (e) => {
        e.preventDefault()
        if (!nameRef.current?.isValid()) return;
        else if (!slugRef.current?.isValid()) return;
        else if (selectedStatus === '0') {
            const notify = () => toast.info('Firma musi posiadać status');
            notify()
            return
        }
        else if (!loginRef.current.isValid()) return
        else if (!passwordRef.current.isValid()) return

        const attachment = attachmentsRef.current.getState();
        let isAttachmentChange;

        if(attachment.lenght = 0 && state?.company?.agreement === undefined){
            isAttachmentChange = false
        }
        else if(attachment[0]?.name === state?.company?.agreement?.name && attachment[0]?.size === state?.company?.agreement?.size){
            isAttachmentChange = false
        }else{
            isAttachmentChange = true
        }
        

        if (nameRef.current?.getValue() === state?.company?.name
            && slugRef.current?.getValue() === state?.company?.slug
            && parseInt(selectedStatus) === state?.company?.status?.id
            && loginRef.current?.getValue() === state?.company?.login
            && passwordRef.current?.getValue() === state?.company?.password
             && !isAttachmentChange
        ) {
                const notify = () => toast.info('Nie wprowadzono żadnych zmian');
                notify()
                return
        }
        

        const companyFormData = new FormData();
        companyFormData.append('name', nameRef.current?.getValue() === state?.company?.name ? '' : nameRef.current?.getValue())
        companyFormData.append('slug', slugRef.current?.getValue() === state?.company?.slug ? '' : slugRef.current?.getValue())
        companyFormData.append('status_id', parseInt(selectedStatus) === state?.company?.status?.id ? '' :  parseInt(selectedStatus))
        companyFormData.append('login', loginRef.current?.getValue() === state?.company?.login ? '' : loginRef.current?.getValue())
        companyFormData.append('password', passwordRef.current?.getValue() === state?.company?.password ? '' : passwordRef.current?.getValue())
        companyFormData.append('agreement', attachment[0] ?? '')
        

        const uuid = state?.company?.uuid;
        const response = await updateCompany(uuid, companyFormData);

        if (response.success) {
            const notify = () => toast.success(`Dane firmy ${state?.company?.name} zostały zaktualizowane`)
            notify()
            navigate('/admin/settings/companies/edit', {
                replace: true, state: {
                    company: response.data
                }
            })
        } else {
            const notify = () => toast.error(`Aktulizowanie danych firmy ${state?.company?.name} nie powiodło się`);
            notify()
        }

        
    }
    return (
        <React.Fragment>
            {showDeleteConfirmation && <ConfirmDelete cancelDeleteHandler={toggleDeleteConfirmation} deleteHandler={deleteCompanyHandler} deleteMessage={`Czy na pewno chcesz usunąć firmę ${state?.company?.name}`} />}
            <div>
                <h1 className="font-semibold m-12 ml-20 text-xl">Edycja firmy: {state?.company?.name}</h1>
                <Panel>
                    <form className="w-full" onSubmit={updateCompanyHandler} noValidate>
                        <div className="flex flex-col gap-5 w-[40%]">
                            <Input
                                initialValue={state?.company?.name}
                                name={'Nazwa'}
                                inputValidatingFunc={validateCompanyName}
                                allowedSigns={allSigns255}
                                inValidMessage={"To pole jest obowiązkowe"}
                                isRequired={true}
                                ref={nameRef}
                            />
                            <div className="flex flex-row items-center">
                                <Input
                                    initialValue={state?.company?.slug}
                                    name={'Slag - skrócona nazwa która się pojawi w adresie url'}
                                    inputValidatingFunc={validateCompanySlug}
                                    inValidMessage={"To pole jest obowiązkowe"}
                                    allowedSigns={allSigns255}
                                    isRequired={true}
                                    ref={slugRef}
                                />
                                <div className="mt-[1.6%]">
                                    <CopyToClipboard content={state?.company?.slug_link} />
                                </div>
                            </div>
                            <Select data={[{ id: 1, label: 'Aktywny' }, { id: 2, label: 'Nieaktywny' }]} name={'Status'} selectedId={selectedStatus} type={'status'} onSelectChange={onSelectStatus} />
                        </div>

                        <hr className="mb-5" />

                        <div className="w-[40%]">
                            <h2 className="text-gray-500 font-semibold mb-5 text-lg">Dane Logowania do nowego zgłoszenia</h2>
                            <div className="flex flex-row items-center">
                                <Input
                                    initialValue={state?.company?.login}
                                    name={'Login'}
                                    inputValidatingFunc={validateCompanyLogin}
                                    allowedSigns={allSigns255}
                                    inValidMessage={companyLoginRequiredInValidMessage}
                                    isRequired={true}
                                    ref={loginRef}
                                />
                                <div className="mt-[1.6%]">
                                    <CopyToClipboard content={state?.company?.login} />
                                </div>
                            </div>
                            <div className="flex flex-row items-center">
                                <Input
                                    initialValue={state?.company?.password}
                                    name={'Hasło'}
                                    inputValidatingFunc={validateCompanyPassword}
                                    allowedSigns={allSigns255}
                                    inValidMessage={companyPasswordRequiredInValidMessage}
                                    isRequired={true}
                                    type={'password'}
                                    ref={passwordRef}
                                />

                                <div className="mt-[1.6%]">
                                    <CopyToClipboard content={state?.company?.password} />
                                </div>
                            </div>
                        </div>

                        <hr className="mb-5" />

                        <div className="w-[40%]">
                            <h2 className="text-gray-500 font-semibold mb-5 text-lg">Dokument polityki prywatności</h2>
                            <Attachments 
                                labelDescription={"(akctepowalne pliki: *.pdf *.doc *.docx)"}
                                allowedFileTypes={['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']}
                                initalList={state?.company?.agreement ? [{
                                    name: state?.company?.agreement?.name,
                                    size: state?.company?.agreement?.size
                                }
                                ]: null}
                                limitSize={1}
                                ref={attachmentsRef} />
                                
                        </div>

                        <div className="flex flex-row-reverse gap-10">
                            <Button value={"Zapisz"} width={'w-[10%]'} isActive={true} />
                            <Button value={'Usuń'} width={'w-[10%]'} type='button' clickHandler={toggleDeleteConfirmation} />
                        </div>
                    </form>
                </Panel>
            </div>
        </React.Fragment>
    );
}
export default CompaniesEditPanel