import axios from 'axios';
import { BASE_API_URL } from '../settings/settings';
import { store } from '../store/store'
import { sessionExpired } from '../store/authSlice';


const getIPFromStore = () => {
  const state = store.getState()
  return state.userInfo.ipAddress;
};

const axiosInstance = axios.create({
  baseURL: `${BASE_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    "Access-Control-Allow-Origin": "*"
  },
});

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.request.use(
  (config) => {
    const ipAddress = getIPFromStore()

    if (config.method === 'get' ) {
        config.params = {
          ...config.params,
          ipAddress: ipAddress,
        };
    } 
    if (config.data instanceof FormData) {
      config.data.append('ipAddress', ipAddress);
    } else {
      if (config.data) {
        config.data = { ...config.data, ipAddress };
      } else {
        config.data = { ipAddress };
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    store.dispatch(sessionExpired());

  }
  return Promise.reject(error);
});


export default axiosInstance;