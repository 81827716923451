import { useState, useRef,forwardRef, useImperativeHandle} from "react";



const Select = forwardRef(({name, data, selectedId, onSelectChange, type, isRequired, inValidMessage}, ref) =>{

    const [selectedOption, setSelectedOption] = useState(parseInt(selectedId))
    const [isTouched, setIsTouched] = useState(false)

    const selectRef = useRef(null)

    const onSelectChangeHandler = (e)=>{
            if(onSelectChange !== undefined){
                onSelectChange({selectedValue : e.target.value})
            }
            setSelectedOption(e.target.value)
        }

    const onBlur = (e)=>{
        setIsTouched(true)
    }

    useImperativeHandle(ref, ()=>({
        getValue: ()=> selectedOption,
        focus: () => {
            selectRef.current.focus()
            selectRef.current.blur()
            selectRef.current.focus()
        }
    }))

    return(
        <div className="flex flex-col gap-3 w-full justify-center text-center text-gray-500 w-full mb-5">
        <label className=" text-left text-ms ml-1 font-semibold">{name}{isRequired ? <span className="text-red-400 ml-1">*</span> : null}</label>
         

        <select name={type} className=" [text-align-last:center] block rounded-lg  py-2 focus:border-emerald focus:outline-none border border-slate-300" value={selectedOption} onBlur={onBlur} onChange={onSelectChangeHandler} ref={selectRef}>
            {data !== 'undefined' ? data.map((option, index) => {
                return <option  key={index} value={type === 'companies' ? option.uuid : option.id}>{type === 'status' || type === 'departments' ? option.label : option.name}</option>}) : null}
            <option value={0}>Wybierz</option>
        </select>
        {isRequired && selectedOption == 0 && isTouched && <p className="text-left text-red-500">{inValidMessage}</p>}
    </div>
    );    
})

export default Select
