import Button from "../Button";
import Input from "../Input";
import { useState, useRef, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import Textarea from "../Textarea";
import Attachments from '../attachments/Attachments';
import DateSelect from "../DateSelect";
import CheckBox from "../CheckBox";
import { toast } from "react-toastify";

import {
    validateName,
    validateEmail,
    validateFlatNumber,
    validateHouseNumber,
    validatePhone,
    validateTicketDescription,
    validateTicketTitle,
    validateZipCode,
    onlyNumber,
    zipCodeSigns,
    emailInputAllowedSigns,
    phoneInputAllowedSigns,
    validateExtraInformations,
    validateSourceOfInformation,
    validateIdentificationData,
    validateCity,
    validateStreet,
    allSigns255,
    allSignsText,
    allSigns1024,
    isRequiredInValidMessage,
    emailInValidMessage,
    phoneRequiredInValidMessage,
    phoneInValidMessage,
    zipCodeInValidMessage,
    companyDepartmentInValidMessage,
    ticketTitleInValidMessage,
    ticketDescriptionInValidMessage,
    identificationDataInValidMessage,
    sourceOfInformationInValidMessage,
    extraInformationsInValidMessage
} from '../../services/validate';
import Captcha from "../Captcha";

const ContactUsForm = ({ sendData, sumbitStatus, reportingType }) => {

    const companyInfo = useLoaderData();

    const titleRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const streetRef = useRef(null);
    const houseNumberRef = useRef(null);
    const flatNumberRef = useRef(null);
    const cityRef = useRef(null);
    const zipCodeRef = useRef(null);
    const descriptionRef = useRef(null);
    const dateSelectRef = useRef(null);
    const attachmentsRef = useRef(null);
    const departmentRef = useRef(null);
    const regulationCheckBoxRef = useRef(null);
    const identificationDataRef = useRef(null);
    const sourceOfInformationRef = useRef(null);
    const evidencesRef = useRef(null);
    const captchaRef = useRef(null);


    useEffect(() => {
        if (sumbitStatus.status === 'failed') {
            const notify = () => toast.error('Błąd podczas wysyłania zgłoszenia');
            notify()
        }

        if(reportingType === 'manifestly'){
            firstNameRef.current.focusInput()
        }else{
            titleRef.current.focusInput()
        }

    }, [sumbitStatus, reportingType])

  

    const [isContactInfoCheckBoxSelected, setIsContactInfoCheckBoxSelected] = useState(false);

    const onContactInfoCheckBoxChange = (e) => {
        if (isContactInfoCheckBoxSelected) {
            setIsContactInfoCheckBoxSelected(false)
        } else {
            setIsContactInfoCheckBoxSelected(true)
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        if (reportingType === 'manifestly' && !firstNameRef.current.isValid()) return
        else if (reportingType === 'manifestly' && !lastNameRef.current.isValid()) return;
        else if (reportingType === 'manifestly' && !emailRef.current.isValid()) return;
        else if (reportingType === 'manifestly' && !phoneRef.current.isValid()) return;

        else if (isContactInfoCheckBoxSelected) {

            if (!streetRef.current.isValid()) return;
            else if (!houseNumberRef.current.isValid()) return;
            else if (!zipCodeRef.current.isValid()) return;
            else if (!cityRef.current.isValid()) return;
            else if (!dateSelectRef.current.isValid()) return;
            else if (!titleRef.current.isValid()) return;
            else if (!descriptionRef.current.isValid()) return;
            else if (regulationCheckBoxRef.current != null && !regulationCheckBoxRef.current.isValid()) return;
        }
        else if (!dateSelectRef.current.isValid()) return;
        else if (!departmentRef.current.isValid()) return;
        else if (!titleRef.current.isValid()) return;
        else if (!descriptionRef.current.isValid()) return;
        else if (!identificationDataRef.current.isValid()) return;
        else if (!sourceOfInformationRef.current.isValid()) return;
        else if (!evidencesRef.current.isValid()) return;
        else if (regulationCheckBoxRef.current != null && !regulationCheckBoxRef.current.isValid()) return;
        else if(!captchaRef.current.isValid()) return;

        const attachments = attachmentsRef.current.getState()
        const formData = new FormData();

        formData.append('title', titleRef.current.getValue())
        formData.append('description', descriptionRef.current.getValue())
        formData.append('company_id', companyInfo.data.id)
        formData.append('department', departmentRef.current.getValue())
        formData.append('violation_date_from', dateSelectRef.current.getValue().fromDate)
        formData.append('violation_date_to', dateSelectRef.current.getValue().toDate)
        formData.append('identification_data', identificationDataRef.current.getValue())
        formData.append('identification_source', sourceOfInformationRef.current.getValue())
        formData.append('evidences', evidencesRef.current.getValue())
        if (reportingType === 'manifestly') {
            formData.append('reporter_email', emailRef.current.getValue())
            formData.append('reporter_phone', phoneRef.current.getValue())
            formData.append('reporter_first_name', firstNameRef.current.getValue())
            formData.append('reporter_last_name', lastNameRef.current.getValue())
        }
        if (isContactInfoCheckBoxSelected) {
            formData.append('reporter_street', streetRef.current.getValue())
            formData.append('reporter_house_number', houseNumberRef.current.getValue())
            formData.append('reporter_flat_number', flatNumberRef.current.getValue())
            formData.append('reporter_city', cityRef.current.getValue())
            formData.append('reporter_zip_code', zipCodeRef.current.getValue())
        }

        let sizeAttachments = 0;

        for (let i = 0; i < attachments.length; i++) {
            sizeAttachments += attachments[i].size
            formData.append('attachments[]', attachments[i]);
        }

        if (sizeAttachments >= 20 * 1000 * 1000) {
            const notify = () => toast.info('Łączny rozmiar plików nie może przekraczać 20MB');
            notify();
            return
        }

        sendData(formData, attachments)
    }

    document.addEventListener('keydown', (key)=>{
        if(key.key==='Enter'){
            onSubmitHandler(key);
        }
    })


    return (

        <div className="bg-gray-100 rounded-lg w-[80%] p-10 mb-[2%]" >
            <form noValidate>

                {reportingType === 'manifestly' &&
                    <div className="flex flex-row gap-5">
                        <div className="basis-1/2">
                            <Input
                                ref={firstNameRef}                                
                                type={'text'}
                                formName={'reporterFirstName'}
                                name={'Imię'}
                                placeholder={'Podaj swoję imię np. Jan'}
                                shadow={true}
                                inValidMessage={isRequiredInValidMessage}
                                inputValidatingFunc={validateName}
                                allowedSigns={allSigns255}

                            />
                        </div>
                        <div className="basis-1/2">
                            <Input
                                ref={lastNameRef}
                                type={'text'}                                
                                formName={'reporterLastName'}
                                name={'Nazwisko'}
                                placeholder={'Podaj swoję nazwisko np. Kowalski'}
                                shadow={true}
                                inputValidatingFunc={validateName}
                                allowedSigns={allSigns255}
                                inValidMessage={isRequiredInValidMessage}

                            />
                        </div>
                    </div>
                }
                {reportingType === 'manifestly' &&
                    <div className="flex flex-row gap-5">
                        <div className="basis-1/2">

                            <Input
                                type={'email'}
                                formName={'reporterEmail'}
                                name={'E-mail'}
                                placeholder={'Podaj swój adres email'}
                                shadow={true}
                                isRequired={true}
                                inValidMessage={emailInValidMessage}
                                ref={emailRef}
                                inputValidatingFunc={validateEmail}
                                allowedSigns={emailInputAllowedSigns}
                            />
                        </div>
                        <div className="basis-1/2">

                            <Input
                                type={'tel'}
                                formName={'reporterPhone'}
                                name={'Numer telefonu'}
                                placeholder={'Podaj swój numer telefonu'}
                                shadow={true}        
                                isRequired={true}            
                                inValidMessage={phoneRequiredInValidMessage}
                                inputValidatingFunc={validatePhone}
                                allowedSigns={phoneInputAllowedSigns}
                                ref={phoneRef}
                            />
                        </div>
                    </div>
                }

                {reportingType === 'manifestly' &&
                    <div className="flex flex-row gap-3 w-full justify-left items-center text-gray-500 w-full mb-5">
                        <label className="text-left text-ms ml-1 font-semibold">Adres do korespondencji:</label>
                        <input onInvalid={F => F.target.setCustomValidity('To pole jest obowiązkowe')} className={`w-7 h-7 border border-green-500 border-solid`} type={'checkbox'} required onClick={onContactInfoCheckBoxChange} />
                    </div>}

                {(reportingType === 'manifestly' && isContactInfoCheckBoxSelected) &&
                    <div className="flex flex-row gap-5">
                        <div className="basis-1/3">
                            <Input                        
                                type={'text'}
                                formName={'reporterStreet'}
                                name={'Ulica'}
                                placeholder={'Podaj ulice'}
                                shadow={true}
                                inValidMessage={isRequiredInValidMessage}
                                ref={streetRef}
                                inputValidatingFunc={validateStreet}
                                allowedSigns={allSigns255}
                            />
                        </div>
                        <div className="basis-1/3">
                            <Input
                                type={'text'}                                
                                formName={'reporterHouseNumber'}
                                name={'Numer domu'}
                                placeholder={'Podaj numer domu '}
                                shadow={true}
                                inValidMessage={isRequiredInValidMessage}
                                ref={houseNumberRef}
                                inputValidatingFunc={validateHouseNumber}
                                allowedSigns={onlyNumber}
                            />

                        </div>
                        <div className="basis-1/3">
                            <Input
                                ref={flatNumberRef}
                                type={'text'}
                                formName={'reporterFlatNumber'}
                                name={'Numer mieszkania'}
                                placeholder={'Podaj numer domu'}
                                shadow={true}
                                inputValidatingFunc={validateFlatNumber}
                                allowedSigns={onlyNumber}
                                inValidMessage={isRequiredInValidMessage}
                            />

                        </div>
                    </div>
                }
                {(reportingType === 'manifestly' && isContactInfoCheckBoxSelected) &&
                    <div className="flex flex-row gap-5">
                        <div className="basis-1/2">
                            <Input
                                type={'text'}
                                formName={'reporterZipCode'}
                                name={'Kod pocztowy'}
                                placeholder={'Podaj kod pocztowy np. 12-123'}
                                shadow={true}                                
                                inValidMessage={zipCodeInValidMessage}
                                ref={zipCodeRef}
                                inputValidatingFunc={validateZipCode}
                                allowedSigns={zipCodeSigns}
                            />
                        </div>

                        <div className="basis-1/2">
                            <Input
                                type={'text'}
                                formName={'reporterCity'}
                                name={'Miejscowość'}
                                placeholder={'Podaj miasto'}
                                shadow={true}
                                inValidMessage={isRequiredInValidMessage}
                                ref={cityRef}
                                inputValidatingFunc={validateCity}
                                allowedSigns={allSigns255}
                            />
                        </div>
                    </div>
                }


                <DateSelect ref={dateSelectRef} isRequired={true} />

                <Input
                    type={'text'}
                    formName={'department'}
                    name={'Dział Spółki, którego dotyczy zgłoszenie'}
                    shadow={true}
                    ref={departmentRef}
                    inputValidatingFunc={validateName}
                    allowedSigns={allSigns255}
                    inValidMessage={companyDepartmentInValidMessage}
                />

                <Input
                    type={'text'}
                    formName={'ticketTitle'}
                    name={'Tytuł naruszenia'}
                    shadow={true}
                    inValidMessage={ticketTitleInValidMessage}
                    ref={titleRef}
                    inputValidatingFunc={validateTicketTitle}
                    allowedSigns={allSigns255}
                />

                <Textarea
                    name={'Zwięzły opis sprawy ze wskazaniem istotnych faktów'}
                    isRequired={true}
                    inValidMessage={ticketDescriptionInValidMessage}
                    inputValidatingFunc={validateTicketDescription}
                    allowedSigns={allSignsText}
                    ref={descriptionRef}
                />

                <Input
                    type={'text'}
                    formName={'identificationData'}
                    name={'Dane identyfikacyjne osób, które mogą mieć związek ze zgłoszeniem, w tym osoby, której naruszenie prawa jest przedmiotem zgłoszenia'}
                    shadow={true}
                    ref={identificationDataRef}
                    inputValidatingFunc={validateIdentificationData}
                    allowedSigns={allSigns1024}
                    inValidMessage={identificationDataInValidMessage}

                />

                <Input
                    type={'text'}
                    formName={'sourceOfInformations'}
                    name={'Źródło wiedzy o naruszeniu'}
                    shadow={true}
                    ref={sourceOfInformationRef}
                    inputValidatingFunc={validateSourceOfInformation}
                    allowedSigns={allSigns1024}
                    inValidMessage={sourceOfInformationInValidMessage}
                />

                <Textarea
                    formName={'extraInformations'}
                    name={'Inne informacje lub dokumenty mające związek z naruszeniem'}
                    isRequired={false}
                    inputValidatingFunc={validateExtraInformations}
                    allowedSigns={allSignsText}
                    ref={evidencesRef}
                    inValidMessage={extraInformationsInValidMessage}
                />

                <Attachments ref={attachmentsRef} name={'Załączniki, w tym dowody uzasadniające lub uprawdopodabniające prawdziwość zgłaszanej informacji'}/>

                <section className=" flex flex-col gap-4 mb-5">
                    <label className="text-gray-500 font-semibold">Zgody</label>
                        {companyInfo?.data?.agreement?.link &&  <CheckBox content={companyInfo?.data?.agreement?.link} inValidMessage={'To pole jest obowiązkowe'} ref={regulationCheckBoxRef} />}
                    <a className="font-semibold text-gray-500 underline" href={companyInfo?.data?.global_agreement} target="_blank">Regulamin świadczenia usług drogą elektroniczną</a>
                </section>

                <section className=" flex flex-col gap-4 mb-5">
                    <label className="text-gray-500 font-semibold">Walidacja bezpieczeństwa <span className="text-red-400 ml-1">*</span></label>
                    <p className="text-gray-500 text-sm">Wpisz poniższe znaki w polu obok</p>
                    <Captcha ref={captchaRef}/>  
                </section>

                <div className="flex flex-row-reverse  justify-between items-center">
                    <Button isActive={true} value={'Wyślij zgłoszenie'} width={'w-[30%]'} clickHandler={onSubmitHandler} type={'submit'} />
                    <p className="text-gray-500 font-semibold"><span className="text-red-400 ml-1">*</span> Pola wymagane</p>
                </div>
            </form>
        </div>
    );
}

export default ContactUsForm
