const Card = ({children, width}) => { 


    return (
        <div className={`${width} flex flex-col gap-10 m-5 bg-gray-100 text-white text-center shadow-2xl rounded-lg px-16 pb-16 pt-8`}>
            {children}
        </div>
    )
}




export default Card;