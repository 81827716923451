import { useState, forwardRef, useImperativeHandle, useRef } from "react"


const CheckBox = forwardRef(({ inValidMessage, content }, ref) => {
    const [isChecBoxSelected, setChechboxSelectStatus] = useState(false)
    const [isCheckboxTouched, setIfChechboxTouched] = useState(false)

    const checkBoxRef = useRef(null);

    const onClickChechBox = (e) => {
        setIfChechboxTouched(true)
        if (!isChecBoxSelected) {
            setChechboxSelectStatus(true)
        } else {
            setChechboxSelectStatus(false)
        }
    }

    const onFocusCheckBox = (e) => {
        setIfChechboxTouched(true)
    }

    useImperativeHandle(ref, () => ({
        isValid: () => {
            if (isChecBoxSelected) {
                return true
            } else {

                checkBoxRef.current.scrollIntoView({
                    behavior: 'smooth', // You can use 'auto' for instant scrolling
                    block: 'center', // Align the element in the center of the view
                    inline: 'nearest'
                  });
                  checkBoxRef.current.focus() 
                // checkBoxRef.current.blur();
                // checkBoxRef.current.focus();
                return false
            }
        }
    }))

    return (
        <div>
            <div className={`flex flex-row w-full justify-left text-left rounded-lg ml-2 bg-gray-100 items-center gap-5 mb-2`}>
                <label className={`flex`}>
                    <input className={`w-8 h-8 focus:outline focus:outline-1 focus:outline-blue-500 `} type={'checkbox'} required onClick={onClickChechBox} onBlur={onFocusCheckBox} ref={checkBoxRef} />
                    <span className="text-red-400 ml-1">*</span>
                </label>

                <label className="font-semibold text-gray-500" dangerouslySetInnerHTML={{__html: content}}/>

            </div>
            {(!isChecBoxSelected && isCheckboxTouched) && <p className="text-red-500">{inValidMessage}</p>}
        </div>
    );
})

export default CheckBox
