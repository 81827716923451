import { useLoaderData} from "react-router-dom";
import Panel from "./Panel";
import Select from "./Select";
import { useRef } from "react";
import DateSelect from "./DateSelect";
import Button from "./Button";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../settings/settings";
import endpoints from "../api/endpoints";
import { getReport } from "../api/apiReports";
const NewReport = (props) => {

    const loaderData = useLoaderData();

    const dateRef = useRef(null)
    const comapniesRef = useRef(null)
    const companies = loaderData.companies;

    const generateRaportHandler = async () => {

        let baseUrl = BASE_API_URL + endpoints.reports.get;

        if (comapniesRef.current.getValue() !== 0) baseUrl += `?company_uuid=${comapniesRef.current.getValue()}`;


        if (dateRef.current.getValue().fromDate !== '' && comapniesRef.current.getValue() === 0 ) baseUrl += `?date_from=${dateRef.current.getValue().fromDate}`;
        else if (dateRef.current.getValue().fromDate !== '') baseUrl += `&date_from=${dateRef.current.getValue().fromDate}`

        if (dateRef.current.getValue().fromDate === '' && comapniesRef.current.getValue() === 0 && dateRef.current.getValue().toDate !== '' ) baseUrl += `?date_to=${dateRef.current.getValue().toDate}`;
        else if (dateRef.current.getValue().toDate !== '') baseUrl += `&date_to=${dateRef.current.getValue().toDate}`;

        
        const response = await getReport(baseUrl);
        if (response.status === 200) {
            const content_disposition = response.headers['content-disposition'].split(';')
            const filename = content_disposition[1].split('=')[1];

            var _url = window.URL.createObjectURL(response.data, { type: 'application/octet-stream' });

            var link = window.document.createElement('a');
            link.href = _url
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            const notify = () => toast.error('Wystąpił błąd podczas generowania raportu');
            notify();
        }
    }


    return (
        <div className="flex flex-col w-full">

            <h1 className="font-semibold m-12 ml-20 text-xl">Nowy raport</h1>
            <Panel >
                <div className="flex flex-col items-left w-full">
                    <DateSelect mode={'2'} hideModeSelect={true} title={'Zakres raportu'} ref={dateRef} notRequired />
                    <div className="w-[37.5%]">
                        <Select data={companies.data} name={'Firma'} selectedId={0} type={'companies'} ref={comapniesRef} />
                    </div>
                    <div className="flex flex-row-reverse">
                        <Button value={'Generuj'} width={'w-[20%]'} clickHandler={generateRaportHandler} isActive={true} />
                    </div>
                </div>
            </Panel>

        </div>
    );
}

export default NewReport


export const viewFile = async (url, headers) => {
    fetch(url, {
        method: 'GET',
        headers
    })
        .then((res) => res.blob())
        .then((blob) => {
            var _url = window.URL.createObjectURL(blob);
            window.open(_url, '_blank').focus();
        })
};