import PaginationPageButton from './PaginationPageButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'


const Pagination = ({controllButtonsClikHander, pageClikHandler, meta}) => {
  
  var paginationIndex = 1
  var currentPage = 0
  var isMorePageTheControllsLimit = true;
  var limit = 5;
  var total = 20;
  var pageCount = 4
  var controllsCount = 5
  var isEnd = false;


  if(meta !== undefined){
      limit = meta.limit
      total = meta.total
      pageCount = Math.ceil(total/limit);
      currentPage = meta.page
      if(pageCount < 5){
        controllsCount = pageCount
      }
  } 

  if(currentPage <= pageCount && currentPage > 0){
    if(pageCount <= controllsCount){
      isMorePageTheControllsLimit=false;
              paginationIndex = 1     
    }else{
        if(pageCount - controllsCount  < currentPage && currentPage <= pageCount){
            isMorePageTheControllsLimit = false
            paginationIndex = pageCount - controllsCount +1
            isEnd=true

          }else{
            if(currentPage !== 1){
              paginationIndex = currentPage - 1 
            }else{
              paginationIndex = currentPage  
            }
          }
    } 
  }else{
    isMorePageTheControllsLimit = false
  }

  return (
    <div className="flex items-center justify-center bg-white px-4 py-3 sm:px-6 m-4">
     
          <nav aria-label="Pagination" className="isolate inline-flex space-x-2 rounded-md shadow-sm">
            <button onClick={controllButtonsClikHander}  value={'prev'} className="relative inline-flex items-center rounded-xl px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
            </button>

            { isEnd ?
            <PaginationPageButton clikHandler={pageClikHandler} index={1}>
            {1}
            </PaginationPageButton> : null
            }

            { isEnd ?
                <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold rounded-xl text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                  ...
               </span> : null
            }   

            {  Array.from({ length: (controllsCount) }).map((_,index) =>{
              return  <PaginationPageButton key={paginationIndex + index} clikHandler={pageClikHandler} index={paginationIndex + index} active={paginationIndex + index === currentPage ? true : false}>
               {paginationIndex + index}
             </PaginationPageButton >
            } )}


            { isMorePageTheControllsLimit ?
            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold rounded-xl text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span> : null
            }

            { isMorePageTheControllsLimit ?
            <PaginationPageButton clikHandler={pageClikHandler} index={pageCount}>
            {pageCount}
            </PaginationPageButton> : null
            }

            <button  onClick={controllButtonsClikHander} value={'next'} className="relative inline-flex items-center rounded-xl px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Next</span>
              <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
            </button>

          </nav>
    </div>
  )
}


export default Pagination;

