import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getEmployees } from '../api/apiEmployeesAndStatuses'

const initialState = {
  isLoading: false,
  data: [],
  error:''

}

export const fetchEmployees = createAsyncThunk('employess/fetchData', async () => {
    const response = await getEmployees()
    return response
  })

const employesSlice = createSlice({
    name: 'employess',
    initialState,
    extraReducers(builder) {
        builder.addCase(fetchEmployees.fulfilled, (state, action) => {
          state.data = action.payload
          state.isLoading = true
        })
        .addCase(fetchEmployees.pending, (state) => {
          state.isLoading = true;
          state.error = '';
        }).addCase(fetchEmployees.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        });
      }
})


export default employesSlice.reducer;
