import Card from "../components/Card";
import LoginForm from "../components/LoginForm";
import { loginUser } from "../api/apiAuth";
import { toast } from "react-toastify";
import { useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn} from "../store/authSlice";
import { useEffect } from "react";
import {setUser} from '../store/userSlice';

const AdminLoginPage = (props) =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(state => state.auth.status);


    useEffect(()=>{
        if(authStatus === 'sessionExpired'){
            const notify = () => toast.error('Twoja sesja wygasła');
            notify();
        }
    },[authStatus])


    const loginUserHandler = async ({credentials})=>{
        const response = await loginUser(credentials);
        

        if(response?.success){
            localStorage.setItem('token',response.data.token);
            localStorage.setItem('user',JSON.stringify(response.data.user));
            dispatch(logIn(response.data.token));
            dispatch(setUser(response.data.user));
            navigate('/admin', {replace: true})
        }
        else{
            const notify = () => toast.error(response?.message ?? 'Nie udało się zalogować, Niepoprawny adres email lub hasło');
            notify()
        }
    }
        
    return(
        <div className='h-screen flex justify-center items-center w-full'>
            <Card width={'w-[28%]'}>
                <h1 className="text-left text-gray-800 text-2xl font-bold ">Panel administratora</h1>
                <p className="text-gray-500 text-left text-xs font-semibold">Zaloguj sie podając adres email i hasło</p>
                <LoginForm sumbitHandler={loginUserHandler}/>
            </Card>   
        </div>
    );    
}

export default AdminLoginPage
