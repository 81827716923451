import Button from "./Button";
import SearchBar from "./SearchBar";
import TableFilter from './TableFilter'
import { useSelector } from "react-redux";
import { useState } from "react";
import useInput from '../hooks/useInput';
import { allSigns1024 } from "../services/validate";
import { useLoaderData } from "react-router-dom";

const TableControls = ({ sumbitHandler }) => {

    const loaderData = useLoaderData();

    const ticketStatuses = useSelector((state) => state.ticketStatuses.data);
    const fetchedEmployees = useSelector((state) => state.employees.data);
    const employees = fetchedEmployees.data ? fetchedEmployees.data : [];
    const statuses = ticketStatuses.data ? ticketStatuses.data : [];
    const companies = loaderData?.companies?.data ? loaderData?.companies?.data : [];  
    const [selectedStatus, setSelectedStatus] = useState(0);

    const [selectedEmployee, setSelectedEmployee] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState(0);


    const {
        entredValue: entredQuery,
        entredValueIsValid: entredQueryValid,
        inputChangeHandler: ticketQueryInputChangeHandler,
        focusInputHandler: ticketQuerynputFocusHandler,
        reset: queryReset
    } = useInput(enterdValue => allSigns1024.test(enterdValue), allSigns1024);

    const onSumbitFormHandler = (e) => {

        e.preventDefault()
        const data = {
            filter_query: entredQuery !== '' ? entredQuery : null,
            filter_user: parseInt(selectedEmployee) > 0 ? parseInt(selectedEmployee) : null,
            filter_status: parseInt(selectedStatus) > 0 ? parseInt(selectedStatus) : null,
            filter_company: selectedCompany !== 0 ? selectedCompany : null,
        }


        sumbitHandler(data)
    }


    const onFormResetHandler = (e) => {
        e.preventDefault();
        queryReset();
        setSelectedStatus(0);
        setSelectedEmployee(0);
        setSelectedCompany(0);

        const data = {
            filter_query: null,
            filter_user: null,
            filter_status: null,
            filter_company: null
        }
        sumbitHandler(data)

    }


    const onSelectedStatusChange = (e) => {
        setSelectedStatus(e.target.value)
    }


    const onSelectedEmployeeChange = (e) => {
        setSelectedEmployee(e.target.value)
    }

    const onSelectedCompanyChange = (e) => {
        setSelectedCompany(e.target.value)
    }

    return (
        <form className="flex flex-row w-full gap-5  my-5 " onSubmit={onSumbitFormHandler} onReset={onFormResetHandler}>
            <SearchBar basis={'basis-[30%]'} changeHandler={ticketQueryInputChangeHandler} blurHandler={ticketQuerynputFocusHandler} value={entredQuery} />
            <TableFilter basis={'basis-[15%]'} type={'status'} data={statuses} name={'Status'} changeHandler={onSelectedStatusChange} selectedId={selectedStatus} />
            <TableFilter basis={'basis-[15%]'} type={'employees'} data={employees} name={'Opiekun'} changeHandler={onSelectedEmployeeChange} selectedId={selectedEmployee} />
            <TableFilter basis={'basis-[15%]'} type={'companies'} data={companies} name={'Firma'} changeHandler={onSelectedCompanyChange} selectedId={selectedCompany} />

            <div className="flex flex-col-reverse basis-[5%] mb-1">
                <Button value={'Szukaj'} isActive={parseInt(selectedEmployee) > 0 || parseInt(selectedStatus) > 0 || entredQueryValid || selectedCompany !== 0} />

            </div>
            {(parseInt(selectedEmployee) > 0 || parseInt(selectedStatus) > 0 || entredQuery !== '' || selectedCompany !== 0) &&
                <div className="flex flex-col-reverse basis-[5%] mb-1">
                    <Button type={'reset'} value={'Wyczyść'} isActive={false} />
                </div>}
        </form>
    );
}

export default TableControls
