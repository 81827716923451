import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './store/store'
import { Provider } from 'react-redux'
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logIn, logOut } from './store/authSlice';
import { getIpAddress, setIpAddress } from './store/userSlice';

const getToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    store.dispatch(logIn(token));
  } else {
    store.dispatch(logOut());
  }
}

const getIp = async () => {
  const userIpAddress = localStorage.getItem('userIpAddress');
  if (userIpAddress) {
    await store.dispatch(getIpAddress())
    const state = store.getState();
    const ip = state.userInfo.ipAddress;
    if (ip !== userIpAddress) {
      store.dispatch(setIpAddress(userIpAddress));
    }
  } else {
    store.dispatch(getIpAddress())
  }
}

getIp()
getToken()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      theme="light"
      transition={Bounce}
    />
    <App />
  </Provider>,
);

