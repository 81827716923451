import { forwardRef, useRef, useImperativeHandle } from "react";
import useInput from "../hooks/useInput";

const Textarea = forwardRef(({name, isRequired, formName, inValidMessage, inputValidatingFunc, allowedSigns, initialValue}, ref) =>{

    const textareaRef = useRef(null);

    const {
        entredValueIsValid,
        entredValue,    
        hasError,
        inputChangeHandler,
        focusInputHandler,      
    }= useInput(inputValidatingFunc, allowedSigns, isRequired, initialValue)



    useImperativeHandle(ref, ()=>({
        isValid:()=>{
            if(entredValueIsValid){
                return true;
            }else{
                textareaRef.current.focus()
                return false;
            }
        },
        getValue:()=>entredValue,
        focusTextarea: ()=>{
            textareaRef.current?.focus();
        }
    }))
        
    return(
        <div className="flex flex-col gap-3 w-full justify-center text-center text-gray-500 w-full mb-5 ">
                <label className=" text-left text-ms ml-1 font-semibold">{name}{isRequired ? <span className="text-red-400 ml-1">*</span> : null}</label>
                <textarea onBlur={focusInputHandler} value={entredValue} ref={textareaRef} onChange={inputChangeHandler}  name={formName} required={isRequired} className={`h-[250px] resize-none block rounded-lg py-2 pl-9 pr-3 w-full focus:border-emerald focus:outline-none border  ${ hasError ? 'border-red-600 focus:border-red-600':'border-slate-300'} disabled:placeholder:text-gray-700 disabled:shadow-none `} /> 
            {(hasError) && <p className="text-left text-red-500 text-ms ml-1">{inValidMessage}</p>}      
    </div>
    );    
})

export default Textarea;
