
const TableFilter = ({ basis, type, data, name, changeHandler, selectedId}) =>{


    return(
        <div className={` ${basis} flex flex-col gap-5`}> 
            <div className="flex flex-row items-center gap-1">
                <label className="font-semibold text-xl ml-2">{name}</label>
                         
            </div>
            <select className=" [text-align-last:center] block rounded-lg text-gray-500  py-[0.6rem]  w-full focus:bordere-emerald focus:outline-none border border-slate-300" name={type} onChange={changeHandler} value={selectedId}>
                {data !== 'undefined' ? data.map((option, index) => { return <option  key={index} value={type === 'companies' ? option.uuid : option.id}>{type === 'status' ? option.label : option.name}</option>}) : null }
                <option value={0}>Wybierz</option>
            </select>
        </div>
    );    
}

export default TableFilter
