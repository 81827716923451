import axiosInstance from "./axiosInstance"
import endpoints from "./endpoints"




export const getStats = async ()=>{


    return await axiosInstance.get(endpoints.dashobard.stats).then((response)=> response.data).catch((error) => {
        if (error.response) {
            return error.response
        } else if (error.request) {
            return error
        } else {
            return error
        }
    })
}