import { NavLink } from "react-router-dom"

const NavButton = ({ svg, color, background, hoverTextColor, onClikHandler, basis, size, to }) => {


    if (color === undefined) {
        color = 'text-gray-700'
    }
    if (basis === undefined) {
        basis = ''
    }

    if (background === undefined) {
        background = ''
    }

    if (hoverTextColor === undefined) {
        hoverTextColor = ''
    }
    if (size === undefined) {
        size = `size-6`
    }

    return (
        <div className={`${color} ${background} ${basis} transition-transform p-5 w-full `}>
            <NavLink end to={to} className={({ isActive, isPending }) => {
                return isActive ? `p-2 rounded-lg ${hoverTextColor} focus:animation-none bg-white active:bg-white hover:transition-all block` : isPending ? `` : `p-2 rounded-lg ${hoverTextColor}  hover:bg-white active:bg-white hover:transition-all block`
            }} onClick={onClikHandler} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`${size}`} >
                    {svg}
                </svg>
            </NavLink>
        </div>
    );
}

export default NavButton
