import Panel from "../Panel";
import { Outlet, useLoaderData, useLocation } from "react-router-dom";
import TicketDetailsNavTab from "./TicketDetailsNavTab";
const TicketDetails = () => {
    const location = useLocation()
    const ticketInfo = useLoaderData();
    if (ticketInfo !== undefined) {
        let tabName;
        if (location.pathname === `/admin/tickets/${ticketInfo?.data?.details?.uuid}`) {
            tabName = `Szczegóły zgłoszenia: ${ticketInfo?.data?.details?.number}`
        }
        else if (location.pathname === `/admin/tickets/${ticketInfo?.data?.details?.uuid}/notes`) {
            tabName = `Notatki zgłoszenia: ${ticketInfo?.data?.details?.number}`
        }
        else if (location.pathname === `/admin/tickets/${ticketInfo?.data?.details?.uuid}/attachments`) {
            tabName = `Załączniki zgłoszenia: ${ticketInfo?.data?.details?.number}`
        }
        else if (location.pathname === `/admin/tickets/${ticketInfo?.data?.details?.uuid}/history`) {
            tabName = `Historia zgłoszenia: ${ticketInfo?.data?.details?.number}`
        }
        return (
            <div>
                <h1 className="font-semibold m-12 ml-20 text-xl">{tabName}</h1>
                <nav className="flex flex-row m-10 ml-20 gap-10">
                    <TicketDetailsNavTab to={`/admin/tickets/${ticketInfo?.data?.details?.uuid}`} >Szczegóły</TicketDetailsNavTab>
                    <TicketDetailsNavTab to={`/admin/tickets/${ticketInfo?.data?.details?.uuid}/notes`}>Notatki</TicketDetailsNavTab>
                    <TicketDetailsNavTab to={`/admin/tickets/${ticketInfo?.data?.details?.uuid}/history`} >Historia</TicketDetailsNavTab>
                    <TicketDetailsNavTab to={`/admin/tickets/${ticketInfo?.data?.details?.uuid}/attachments`}>Załączniki</TicketDetailsNavTab>
                </nav>
                <Panel>                    
                    <Outlet context={{ticketUUID:ticketInfo?.data?.details?.uuid}}/>
                </Panel>
            </div>
        );
    } else {
        return (<div className='flex flex-col items-center justify-center h-dvh'>
            <p className='font-semibold text-xl'>Nie ma zgłoszenia o takim numerze</p>
        </div>)
    }
}
export default TicketDetails