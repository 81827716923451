import Input from "../Input";
import Select from "../Select";
import Button from "../Button";
import { useLoaderData, useNavigate } from "react-router-dom";
import { formatDate } from "../../services/date";
import { useSelector } from "react-redux";
import { patchTicketDetails, getTicketInfo } from '../../api/apiTickets';
import { useState, useEffect, useRef , Fragment} from 'react'
import { toast } from "react-toastify";
const TicketDetailsPanel = (props) => {

    const ticketStatuses = useSelector((state) => state.ticketStatuses.data);
    const fetchedEmployees = useSelector((state) => state.employees.data);
    const navigate = useNavigate()
    const ticketUUID = useLoaderData();

    const [ticketData, setTicketData] = useState(null)

    const employees = fetchedEmployees.data ? fetchedEmployees.data : [];
    const statuses = ticketStatuses.data ? ticketStatuses.data : [];

    const employeesRef = useRef(null);
    const statusesRef = useRef(null);
    const finishedAtRef = useRef(null);
    const updateAtRef = useRef(null);

    useEffect(() => {
        const fetchInfo = async (ticketUUID) => {
            const response = await getTicketInfo(ticketUUID)
            if (response.success) {
                setTicketData(response.data)
            }
        }
        fetchInfo(ticketUUID)
    }, [ticketUUID, setTicketData, navigate])


    const ticketDetails = ticketData?.details !== undefined ? {
        "uuid": ticketData.details.uuid ? ticketData.details.uuid : '-',
        "number": ticketData.details.number ? ticketData.details.number : '-',
        "title": ticketData.details.title ? ticketData.details.title : '-',
        "description": ticketData.details.description ? ticketData.details.description : '-',
        "status": ticketData.details.status ? ticketData.details.status : '-',
        "reporter": {
            "email": ticketData.details.reporter.email ? ticketData.details.reporter.email : '-',
            "phone": ticketData.details.reporter.phone ? ticketData.details.reporter.phone : '-',
            "firstName": ticketData.details.reporter.first_name ? ticketData.details.reporter.first_name : '-',
            "lastName": ticketData.details.reporter.last_name ? ticketData.details.reporter.last_name : '-',
        },
        "user": {
            "id": ticketData.details.user.id ? ticketData.details.user.id : 0,
            "name": ticketData.details.user.name ? ticketData.details.user.name : '-',
        },
        "company": {
            "name": ticketData.details.company.name ? ticketData.details.company.name : '-'
        },
        "department": ticketData.details.department ? ticketData.details.department : '-',
        "created_at": ticketData.details.created_at ? formatDate(ticketData.details.created_at) : '-',
        "updated_at": ticketData.details.updated_at ? formatDate(ticketData.details.updated_at) : '-',
        "identification_data": ticketData.details.identification_data ? ticketData.details.identification_data : '-',
        "identification_source": ticketData.details.identification_source ? ticketData.details.identification_source : '-',
        "evidences": ticketData.details.evidences ? ticketData.details.evidences : '-',
        'violation_date_from': ticketData.details.violation_date_from ? formatDate(ticketData.details.violation_date_from, false) : '-',
        'violation_date_to': ticketData.details.violation_date_to ? formatDate(ticketData.details.violation_date_to, false) : '-',
        'finished_at': ticketData.details.finished_at ? formatDate(ticketData.details.finished_at) : '-'
    } : null

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        if (parseInt(employeesRef.current.getValue()) !== ticketDetails.user.id || parseInt(statusesRef.current.getValue()) !== ticketDetails.status.id) {
            if (parseInt(employeesRef.current.getValue()) === 0) {
                const notify = () => toast.info(`Zgłoszenie musi posiadać opiekuna`)
                notify()
                return
            }
            if (parseInt(statusesRef.current.getValue()) === 0) {
                const notify = () => toast.info(`Zgłoszenie musi posiadać status`)
                notify()
                return
            }
            const data = {
                'user_id': parseInt(employeesRef.current.getValue()),
                'status_id': parseInt(statusesRef.current.getValue())
            }
            const response = await patchTicketDetails(data, ticketDetails.uuid)
            if (response.success === true) {
                const notify = () => toast.success(`Zgłoszenie o numerze ${ticketDetails?.number} zostało zaktualiozwane`)
                notify()
                const finishedDate =  response.data.details.finished_at ? formatDate(response.data.details.finished_at) : '-'
                const updateDate =  response.data.details.updated_at ? formatDate(response.data.details.updated_at) : '-'
                finishedAtRef.current.setValue(finishedDate)
                updateAtRef.current.setValue(updateDate)
                setTicketData(response.data)                
            } else {
                const notify = () => toast.error(`Wystąpił błąd podczas aktualizacji zgłoszenie o numerze ${ticketDetails?.number}`)
                notify()
            }
        } else {
            const notify = () => toast.info(`Nie dokonano żadnych zmian w polu opiekun lub status`)
            notify()
        }
    }

    return (
        <Fragment>
            {ticketData && 
            <form onSubmit={onSubmitHandler} className="w-full">
                <div className=" flex flex-row gap-5 ">
                    <div className="  w-[80%]">
                        <Input disabled={true} name={'Nazwa firmy'} initialValue={ticketDetails?.company?.name} formName={'companyName'} inputValidatingFunc={(entredValue) => { }} />
                        <Input disabled={true} name={'Imię'} initialValue={ticketDetails?.reporter?.firstName} inputValidatingFunc={(entredValue) => { }} />
                        <Input disabled={true} name={'Nazwisko'} initialValue={ticketDetails?.reporter?.lastName} inputValidatingFunc={(entredValue) => { }} />
                        <Input disabled={true} name={'Email'} initialValue={ticketDetails?.reporter?.email} inputValidatingFunc={(entredValue) => { }} />
                        <Input disabled={true} name={'Numer telefonu'} initialValue={ticketDetails?.reporter?.phone} inputValidatingFunc={(entredValue) => { }} />
                        <Select data={employees} name={'Opiekun'} selectedId={ticketDetails?.user?.id} type={'employees'} ref={employeesRef} />
                        <Select data={statuses} name={'Status'} selectedId={ticketDetails?.status?.id} type={'status'} ref={statusesRef} />
                        <Input disabled={true} name={'Dział Spółki, którego dotyczy zgłoszenie'} initialValue={ticketDetails?.department} inputValidatingFunc={(entredValue) => { }} />
                        <Input disabled={true} name={'Tytuł zgłoszenia'} initialValue={ticketDetails?.title} inputValidatingFunc={(entredValue) => { }} />
                    </div>
                    <div className=' w-[80%]'>
                        <Input disabled={true} name={'Data utworzenia'} initialValue={ticketDetails?.created_at} inputValidatingFunc={(entredValue) => { }} />
                        <Input disabled={true} name={'Data zakończenia'} initialValue={ticketDetails?.finished_at} inputValidatingFunc={(entredValue) => { }} ref={finishedAtRef} />
                        <Input disabled={true} name={'Data ostatniej modyfikacji'} initialValue={ticketDetails?.updated_at} inputValidatingFunc={(entredValue) => { }} ref={updateAtRef}/>
                        <Input disabled={true} name={'Data wystąpienia naruszenia'} initialValue={ticketDetails?.violation_date_from === ticketDetails?.violation_date_to ? ticketDetails?.violation_date_from : ticketDetails?.violation_date_from + ' - ' + ticketDetails?.violation_date_to} inputValidatingFunc={(entredValue) => { }} />
                        <div className="flex flex-col gap-2 justify-center text-gray-500 mb-5">
                            <label className="text-left text-ms ml-1 font-semibold">Źródło wiedzy o naruszeniu</label>
                            <p className="block rounded-lg py-2 pl-9 pr-3  text-gray-600 border border-slate-300 bg-gray-50 break-all" >{ticketDetails?.identification_source}</p>
                        </div>
                        <div className="flex flex-col gap-2 justify-center text-gray-500 mb-5">
                            <label className="text-left text-ms ml-1 font-semibold">Dane identyfikacyjne osób, które mogą mieć związek ze zgłoszeniem, w tym osoby, której naruszenie prawa jest przedmiotem zgłoszenia</label>
                            <p className="block rounded-lg py-2 pl-9 pr-3 text-gray-600 border border-slate-300 bg-gray-50 break-all" >{ticketDetails?.identification_data}</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 justify-center text-gray-500 mb-5">
                    <label className="text-left text-ms ml-1 font-semibold">Zwięzły opis sprawy ze wskazaniem istotnych faktów</label>
                    <p className="block rounded-lg py-2 pl-9 pr-3  text-gray-600 border border-slate-300 bg-gray-50 break-all" >{ticketDetails?.description}</p>
                </div>
                <div className="flex flex-col gap-2 justify-center  text-gray-500  mb-5">
                    <label className="text-left text-ms ml-1 font-semibold">Inne informacje lub dokumenty mające związek z naruszeniem</label>
                    <p className="block rounded-lg py-2 pl-9 pr-3 text-gray-600 border border-slate-300 bg-gray-50 break-all" >{ticketDetails?.evidences}</p>
                </div>
                <div className="flex flex-row-reverse gap-10">
                    <Button value={"Zapisz"} width={'w-[5%]'} isActive={true} />
                </div>
            </form>
            }
        </Fragment>
    );
}
export default TicketDetailsPanel