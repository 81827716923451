// ========================
// Allowed Signs
// ========================

// export const onlyLetters = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ .]{0,65535}$/;
export const phoneInputAllowedSigns = /^[\d]{0,9}?$/;
export const emailInputAllowedSigns = /^[\d a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ.]*@?[\da-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]*.?[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]*?$/;
export const allSigns255 = /^.{1,255}$/;
export const allSigns1024 = /^.{1,1024}$/;
export const allSignsText = /^.{1,65535}$/;
export const zipCodeSigns = /^[\d]{0,2}-?[\d]{0,3}$/;
export const onlyNumber = /^[\d]{0,10}$/;

// ========================
// Note Validations
// ========================

export const noteTitleRegexPattern = /^.{1,255}$/;
export const noteDescriptionRegexPattern = /^.{1,65535}$/;

export const validateNoteTitle = (entredValue) => noteTitleRegexPattern.test(entredValue.trim());
export const validateNoteDescription = (entredValue) => noteDescriptionRegexPattern.test(entredValue.trim());

// ========================
// User Validations
// ========================

export const nameRegexPattern = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ. ]{1,255}$/;
export const phoneRegexPattern = /^\d{9}$/;
export const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const loginFormPasswordRegexPattern = /^.{1,255}$/;
export const passwordRegexPattern = /^.{1,255}$/;
export const newPasswordRegexPattern = /^.{8,255}$/;
export const clientLoginNameRegexPattern = /^.{1,255}$/;

export const emailInValidMessage = "Podaj poprawny adres email";
export const passwordInValidMessage = "To pole jest obowiązkowe";
export const newPasswordInValidMessage = "To pole jest obowiązkowe, minimum 8 znaków";
export const phoneRequiredInValidMessage = "To pole jest obowiązkowe, musi posiadać 9 znaków"
export const phoneInValidMessage = "Nie poprawne dane, pole musi posiadać 9 znaków"
export const clientLoginNameInValidMessage = "To pole jest obowiązkowe"

export const validateName = (entredValue) => nameRegexPattern.test(entredValue.trim());
export const validatePhone = (entredValue) => phoneRegexPattern.test(entredValue.trim());
export const validateEmail = (entredValue) => emailRegexPattern.test(entredValue.trim());
export const validatePassword = (entredValue) => passwordRegexPattern.test(entredValue.trim());
export const validateNewPassword = (entredValue) => newPasswordRegexPattern.test(entredValue.trim());
export const validateClientLoginName  = (entredValue) => clientLoginNameRegexPattern.test(entredValue.trim());

// ========================
// Address Validations
// ========================

export const cityRegexPattern = /^.{1,225}$/;
export const streetRegexPattern = /^.{1,225}$/;
export const houseNumberRegexPatter = /^[\d]{1,255}$/;
export const flatNumberRegexPatter = /^[\d]{1,255}$/;
export const zipCodeRegexPattern = /^[\d]{2}-[\d]{3}$/;

export const isRequiredInValidMessage = "To pole jest obowiązkowe";
export const zipCodeInValidMessage = "To pole jest obowiązkowe, akceptowalny format np. 12-123";

export const validateCity = (entredValue) => cityRegexPattern.test(entredValue.trim());
export const validateStreet = (entredValue) => streetRegexPattern.test(entredValue.trim());
export const validateHouseNumber = (entredValue) => houseNumberRegexPatter.test(entredValue.trim());
export const validateFlatNumber = (entredValue) => flatNumberRegexPatter.test(entredValue.trim());
export const validateZipCode = (entredValue) => zipCodeRegexPattern.test(entredValue.trim());

// ========================
// Ticket Validations
// ========================

export const ticketTitleRegexPattern = /^.{1,225}$/;
export const ticketDescriptionRegexPattern = /^.{1,65535}$/;

export const ticketTitleInValidMessage = "Nie poprawne dane";
export const ticketDescriptionInValidMessage = "Nie poprawne dane";

export const ticketRequiredTitleInValidMessage = "To pole jest obowiązkowe";
export const ticketDescriptionRequiredInValidMessage = "To pole jest obowiązkowe";


export const validateTicketTitle = (entredValue) => ticketTitleRegexPattern.test(entredValue.trim());
export const validateTicketDescription = (entredValue) => ticketDescriptionRegexPattern.test(entredValue.trim());

// ========================
// Company Validations
// ========================

export const companyNameRegexPattern = /^.{1,225}$/;
export const companySlugRegexPattern = /^.{1,225}$/;
export const companyDepartmentRegexPattern = /^.{1,225}$/;
export const companyLoginRegexPatter =  /^.{1,225}$/;
export const companyPasswordRegexPatter =  /^.{1,225}$/;


export const companyNameInValidMessage = "Nie poprawne dane";
export const companySlugInValidMessage = "Nie poprawne dane";
export const companyDepartmentInValidMessage = "Nie poprawne dane";

export const companyNameRequiredTitleInValidMessage = "To pole jest obowiązkowe";
export const companySlugRequiredInValidMessage = "To pole jest obowiązkowe";
export const companyLoginRequiredInValidMessage = "To pole jest obowiązkowe";
export const companyPasswordRequiredInValidMessage = "To pole jest obowiązkowe";


export const validateCompanyName = (entredValue) => companyNameRegexPattern.test(entredValue.trim());
export const validateCompanySlug = (entredValue) => companySlugRegexPattern.test(entredValue.trim());
export const validateCompanyDpartment = (entredValue) => companyDepartmentRegexPattern.test(entredValue.trim());
export const validateCompanyLogin = (entredValue) => companyLoginRegexPatter.test(entredValue.trim());
export const validateCompanyPassword = (entredValue) => companyPasswordRegexPatter.test(entredValue.trim());

// ========================
// Miscellaneous Validations
// ========================

export const extraInformationsRegexPattern = /^.{1,65535}$/;
export const sourceOfInformationRegexPattern = /^.{1,1024}$/;
export const identificationDataRegexPattern = /^.{1,1024}$/;
export const extraInformationsInValidMessage = "Nie poprawne dane";
export const sourceOfInformationInValidMessage = "Nie poprawne dane";
export const identificationDataInValidMessage = "Nie poprawne dane";

export const validateExtraInformations = (entredValue) => extraInformationsRegexPattern.test(entredValue.trim());
export const validateSourceOfInformation = (entredValue) => sourceOfInformationRegexPattern.test(entredValue.trim());
export const validateIdentificationData = (entredValue) => identificationDataRegexPattern.test(entredValue.trim());

