// import axiosInstance from './axiosInstance';
// import endpoints from './endpoints';
// import { API_TOKEN } from '../settings/settings';

import axios from 'axios';

export const getUserIpAddress = async () =>{
 
    const url = 'https://api.ipify.org?format=json'

    return await axios.get(url).then((response)=>{
        return response.data
    }).catch((error)=>{
        if(error.response){
            return error.response.data
        }else if(error.request){
            return error.request
        }else {      
            return error            
     }})

}

