import { useSelector, useDispatch } from 'react-redux'
import { createBrowserRouter, RouterProvider, Navigate, redirect } from 'react-router-dom';
import { getStats } from './api/apiStats';
import { getCompanies, getCompanyInfo } from './api/apiCompany';
import { getTicketInfo, getTicketNotesList, getTicketLogsList } from './api/apiTickets';
import { fetchStatuses } from './store/ticketStatusesSlice';
import { fetchEmployees } from './store/employessSlice';

import Adminpage from './pages/AdminPage';
import AdminLoginPage from './pages/AdminLoginPage'
import ClientLoginPage from './pages/ClientLoginPage'
import TicketsList from './components/tickets/TicketsList';
import TicketDetails from './components/tickets/TicketDetails'
import Loader from './components/Loader';
import ContactUs from './pages/ContactUs'
import ErrorPage from './pages/ErrorPage';
import Root from './components/Root';
import TicketNotesPanel from './components/tickets/TicketNotesPanel';
import TicketNoteEdit from './components/tickets/TicketNoteEdit'
import TicketNoteNew from './components/tickets/TicketNoteNew'
import Dashboard from './components/Dashboard';
import TicketHistoryPanel from './components/tickets/TicketHistoryPanel';
import UsersPanel from './components/users/UsersPanel';
import SettingsPanel from './components/SettingsPanel';
import { getUsers } from './api/apiUsers';
import UsersEditPanel from './components/users/UsersEditPanel';
import UsersNewPanel from './components/users/UsersNewPanel';
import ComapniesPanel from './components/companies/ComapniesPanel';
import CompaniesNewPanel from './components/companies/CompaniesNewPanel';
import CompaniesEditPanel from './components/companies/CompaniesEditPanel';
import TicketAttachmentsPanel from './components/tickets/TicketAttachmentsPanel';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ReportsList from './components/ReportsList';
import NewReport from './components/NewReport';
import TicketDetailsPanel from './components/tickets/TicketDetailsPanel';


const ProtectedRoute = ({ children }) => {

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    if (!isAuthenticated) {
        return <Navigate to='/admin/login' />;
    }
    return children;
};


const CompanyFormProtection = ({ children }) => {

    const isAuthenticated = useSelector((state) => state.auth.clientLogIn);

    if (!isAuthenticated) {
        return <Navigate to='/zgloszenie' />;
    }
    return children;
};


export default function App() {

    const dispatch = useDispatch();

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    loader: () => {
                        return redirect('/zgloszenie')
                    }
                },
                {
                    path: 'zgloszenie',
                    element: <ClientLoginPage />
                },
                {
                    path: "/admin",
                    element: (
                        <ProtectedRoute>
                            <Adminpage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                    children: [
                        {
                            index: 'tickets',
                            element: <TicketsList />,
                            async loader() {
                                try {
                                    dispatch(fetchStatuses())
                                    dispatch(fetchEmployees())
                                    const companies = await getCompanies()
                                    return { companies }
                                } catch (error) {
                                }
                            }
                        },
                        {
                            path: "tickets",
                            element: (
                                <TicketsList />
                            ),
                            async loader() {
                                try {
                                    dispatch(fetchStatuses())
                                    dispatch(fetchEmployees())
                                    const companies = await getCompanies()
                                    return { companies }
                                } catch (error) {
                                }
                            }

                        },
                        {
                            path: 'dashboard',
                            element: (
                                <Dashboard />
                            ),
                            async loader() {
                                const stats = await getStats()
                                return stats;
                            }
                        },
                        {
                            path: 'reports',
                            element: (
                                <ReportsList />
                            )
                        },
                        {
                            path: 'reports/new',
                            element: (
                                <NewReport />
                            ),
                            async loader() {
                                const employees = await getUsers()
                                const companies = await getCompanies()
                                return { employees, companies }
                            }
                        },

                        {
                            path: "tickets/:ticketUUID",
                            element: (
                                <TicketDetails />
                            ),
                            async loader({ params }) {
                             try {
                                    const ticketUUID = params.ticketUUID
                                    const ticketInfo = await getTicketInfo(ticketUUID);
                                    dispatch(fetchStatuses())
                                    dispatch(fetchEmployees())
                                    return ticketInfo
                                } catch (error) {

                                }
                            },
                            children: [
                                {
                                    index: true,
                                    element: <TicketDetailsPanel />,
                                    async loader({ params }) {
                                        try {
                                            const ticketUUID = params.ticketUUID
                                            dispatch(fetchStatuses())
                                            dispatch(fetchEmployees())
                                            return ticketUUID
                                        } catch (error) {

                                        }
                                    },
                                },
                                {
                                    path: "notes",
                                    element: <TicketNotesPanel />,
                                    errorElement: <ErrorPage />,
                                    async loader({ params }) {
                                        const ticketUUID = params.ticketUUID
                                        const notes = await getTicketNotesList(ticketUUID)
                                        return notes
                                    }
                                },
                                {
                                    path: "history",
                                    element: <TicketHistoryPanel />,
                                    errorElement: <ErrorPage />,
                                    async loader({ params }) {
                                        const ticketUUID = params.ticketUUID
                                        const notes = await getTicketLogsList(ticketUUID)
                                        return notes
                                    }
                                },
                                {
                                    path: "attachments",
                                    element: <TicketAttachmentsPanel />,
                                    errorElement: <ErrorPage />
                                },
                            ]

                        },
                        {
                            path: "tickets/:ticketUUID/notes/edit",
                            element: <TicketNoteEdit />,
                            errorElement: <ErrorPage />,
                            loader: ({ params }) => {
                                const ticketUUID = params.ticketUUID

                                return ticketUUID
                            }
                        },
                        {
                            path: "tickets/:ticketUUID/notes/new",
                            element: <TicketNoteNew />,
                            errorElement: <ErrorPage />,
                            loader: ({ params }) => {
                                const ticketUUID = params.ticketUUID
                                return ticketUUID
                            }
                        },
                        {
                            path: "settings",
                            element: <SettingsPanel />,
                            errorElement: <ErrorPage />,
                            children: [
                                {
                                    index: true,
                                    loader: async () => redirect("/admin/settings/users")
                                },
                                {
                                    path: "users",
                                    element: <UsersPanel />,
                                    errorElement: <ErrorPage />,
                                    loader: async () => {
                                        const users = await getUsers()
                                        return users
                                    }
                                },

                                {
                                    path: "companies",
                                    element: <ComapniesPanel />,
                                    errorElement: <ErrorPage />,
                                    loader: async () => {
                                        const companies = await getCompanies()
                                        return companies
                                    }
                                }
                            ]
                        },
                        {
                            path: 'settings/users/edit',
                            element: <UsersEditPanel />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'settings/users/new',
                            element: <UsersNewPanel />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'settings/companies/edit',
                            element: <CompaniesEditPanel />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'settings/companies/new',
                            element: <CompaniesNewPanel />,
                            errorElement: <ErrorPage />,
                        },
                    ],

                },
                {
                    path: "/admin/login",
                    element: <AdminLoginPage />,
                    errorElement: <ErrorPage />
                },
                {
                    path: "/admin/password/forget",
                    element: <ForgotPasswordPage />,
                    errorElement: <ErrorPage />
                },
                {
                    path: "/admin/password/reset",
                    element: <ResetPasswordPage />,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/:companyID/ticket',
                    element: (
                        < CompanyFormProtection >
                            < ContactUs />
                        </CompanyFormProtection>),
                        
                    fallbackElement: <Loader />,
                    async loader({ params }) {
                        const companyId = params.companyID
                        const response = await getCompanyInfo(companyId)
                        return response.data
                    },
                    errorElement: <ErrorPage />
                },
                {
                    path: '/error',
                    element: <ErrorPage />,
                    errorElement: <ErrorPage />
                }
            ]
        }
    ]);


    return (

        <RouterProvider router={router} fallbackElement={<Loader />} />
    );
}



