
const StatCard = ({ title, amount, bgColor}) => {

    return (

        <div className={`px-6 py-3 rounded-2xl text-white shadow m-5 ${bgColor ? bgColor : 'bg-white' }`}>
                <div className="flex items-left text-sm font-medium  ">
                    <label><span>{title}</span>: {amount}</label>
                </div>
        </div >
    );
}

export default StatCard;
