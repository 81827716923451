import { useSelector } from 'react-redux';
import UserSubMenu from './UserSubMenu';
const TopNavigation = ({basis}) =>{

    const user = useSelector(state => state.userInfo.user);
        
    return(
        <div className={`${basis} flex flex-row items-center justify-between border-b border-gray-100 min-h-[81px] max-h-[81px] w-full`}>
            <h1 className=" pl-10 text-2xl font-semibold text-nowrap">Notificatio - portal dla sygnalistów</h1>
            
            <div className="flex flex-row-reverse items-center justify-start gap-5 mr-10">
                <UserSubMenu name={user?.name} email={user?.email}/>
                <h4 className="text-nowrap">{user.name}</h4>
                {/* <NavButton basis="text-center" svg={ <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />}/> */}
                {/* <NavButton  size='size-8'svg={ <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />} />  */}
            </div>
        </div>
    );    
}

export default TopNavigation


