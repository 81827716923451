import Card from "../components/Card";
import Input from "../components/Input";
import { emailInputAllowedSigns, validateEmail, emailInValidMessage } from "../services/validate";
import React, { useRef, useState } from "react";
import Button from "../components/Button";
import { toast } from "react-toastify";
import { sendResetPasswordEmail } from "../api/apiUsers";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const ForgotPasswordPage = (props) =>{
        
    const [requestStatus, setRequestStatus] = useState({status: null});

    const emailRef = useRef(null);

    const onSendResetEmailHandler = async (e) => {
        e.preventDefault()

        if (!emailRef.current.isValid()) return

        setRequestStatus({ status: 'send' });

        const response = await sendResetPasswordEmail(emailRef.current.getValue());

        if (response.status === 200) {
            if (response.data.success) {
                setRequestStatus({ status: 'success', 
                    message: response.data.message
                 });                
            }
      
        }
        else if (response.status === 404){
            const notify = () => toast.error(response.data.message)
            notify()
            setRequestStatus({ status: null });
            return
            
        }
        else {
            const notify = () => toast.error('Coś poszło nie tak')
            notify()
            setRequestStatus({ status: null });
        }

    }

    return (
        <div className='h-screen flex justify-center items-center w-full'>

            {(requestStatus.status === 'send') && <React.Fragment>
                                    <div className='fixed top-0 right-0 left-0 z-50 w-full h-dvh flex flex-col justify-center text-center items-center bg-[rgba(0,0,0,0.5)]'>
                                    <Loader message={"Wysyłanie zgłoszenia"}/>
                                    </div>

                                </React.Fragment>}

            <Card width={'w-[28%]'}>
                {(requestStatus.status === null || requestStatus.status === 'send') &&
                    <React.Fragment>
                        <h1 className="text-left text-gray-800 text-2xl font-bold ">Odzyskiwanie hasła</h1>
                        
                        <Input
                            name={'Adres email'}
                            allowedSigns={emailInputAllowedSigns}
                            inputValidatingFunc={validateEmail}
                            inValidMessage={emailInValidMessage}
                            isRequired={true}
                            ref={emailRef} />
                        <Button isActive={true} value={"Wyślij link do resetu hasła"} type={'button'} clickHandler={onSendResetEmailHandler} />
                        <Link to={'/admin/login'} className={'text-gray-600 text-left hover:underline'} >Zaloguj się</Link>
                    </React.Fragment>
                }

                {requestStatus.status === 'success' &&
                        <div className='flex flex-col items-center justify-center  bg-gray-100 rounded-lg p-10 gap-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  className="size-20 fill-green-500">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                        </svg>
                        <p className="text-gray-600 text-left">{requestStatus.message}</p>
                        <Link to={'/admin/login'} className={'text-gray-600 text-left hover:underline'} >Zaloguj się</Link>
                    </div>           
                }

            </Card>
        </div> 
    )
}

export default ForgotPasswordPage
