
import { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import useInput from '../hooks/useInput';

const Input = forwardRef(({ name, type, svg, disabled, placeholder, formName, noShadow, height, isRequired, inValidMessage, id, inputValidatingFunc, allowedSigns, changeHandler, initialValue, maxDate }, ref) => {

    const [isPasswordHide, setPasswordIshide] = useState(true);
    const [inputType, setInputType] = useState(type);
    const inputRef = useRef();
    const [showError, setShowError] = useState(false);

    const {
        reset,
        entredValue,
        entredValueIsValid,
        hasError,
        inputChangeHandler,
        focusInputHandler,
        setValue: setNewValue
    } = useInput(inputValidatingFunc, allowedSigns, isRequired, initialValue);

    useEffect(() => {        
            setShowError(hasError)        
    }, [hasError, setShowError])

    const onChangeHandler = (e) => {
        inputChangeHandler(e)
        if (changeHandler) {
            changeHandler(e.target.value)
        }
    }

    useImperativeHandle(ref, () => ({
        getValue: () => entredValue,
        focusInput: () => { inputRef.current?.focus() },
        isValid: () => {
            if (entredValueIsValid) {
                return true
            } else {
                inputRef.current?.focus()
                inputRef.current?.blur()
                inputRef.current?.focus()
                return false
            }
        },
        hideError: () => {
            setShowError(false)
        },
        showError: () => {
            setShowError(true)
        },
        reset: () => {
            setShowError(false);
            reset()
        },
        setValue: (newValue)=>{
            setNewValue(newValue);
        }
    }));

    const togglePasswordVisibilityHandler = () => {
        if (isPasswordHide) {
            setPasswordIshide(false)
            setInputType("text")
        } else {
            setPasswordIshide(true)
            setInputType("password")
        }
    }

    return (
        <div className="flex flex-col gap-2 w-full justify-center text-center text-gray-500 w-full mb-5">
            <label htmlFor={id} className="text-left text-ms ml-1 font-semibold">{name}{isRequired ? <span className="text-red-400 ml-1">*</span> : null}</label>

            <label className="relative block">
                <span className="sr-only">Search</span>
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        {svg}
                    </svg>
                </span>
                <input
                    max={maxDate}
                    ref={inputRef}
                    
                    id={id}
                    value={entredValue}
                    className={`block rounded-lg ${height !== 'undefined' ? `h-[${height}]` : ''}${noShadow === 'undefined' ? 'shadow-lg' : ''} py-2 pl-9 pr-8 w-full focus:border-emerald focus:outline-none border ${hasError ? 'border-red-600 focus:border-red-600' : 'border-slate-300'}  disabled:placeholder:text-gray-600 disabled:text-gray-600 disabled:shadow-none disabled:bg-gray-50`}
                    type={inputType}
                    disabled={disabled ? 'disabled' : ''}
                    placeholder={placeholder}
                    name={formName}
                    required={isRequired}
                    onBlur={focusInputHandler}
                    onChange={onChangeHandler}

                />

                {type === "password" && isPasswordHide &&
                    <span className="absolute inset-y-0 right-2 flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5" onClick={togglePasswordVisibilityHandler}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                    </span>
                }
                {type === "password" && !isPasswordHide &&
                    <span className="absolute inset-y-0 right-2 flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5" onClick={togglePasswordVisibilityHandler} >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    </span>
                }

            </label>
            {showError && <p className="text-left text-red-500 text-ms ml-1 display-inline">{inValidMessage}</p>}
        </div>
    );
})

export default Input
