import Button from "../Button";
import Input from "../Input";
import Panel from "../Panel";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useRef } from "react";
import { createTicketNote } from "../../api/apiTickets";
import React, {useEffect} from 'react';
import { toast } from "react-toastify";
import Textarea from "../Textarea";
import { validateNoteTitle, validateNoteDescription, allSigns255, allSignsText } from "../../services/validate";

const TicketNoteNew = (props) => {
    const ticketUUID = useLoaderData()
    const navigate = useNavigate();
    const noteContentTextareaRef = useRef(null)
    const noteTitleInputRef = useRef(null)
    useEffect(()=>{
        if(noteTitleInputRef.current !== null){
            noteTitleInputRef.current.focusInput();
        }
    }, [noteTitleInputRef])
    const saveNoteHandler = async (e) => {
        e.preventDefault();
        if (!noteTitleInputRef.current?.isValid()) return;
        else if (!noteContentTextareaRef.current?.isValid()) return;
        const data = {
            'title': noteTitleInputRef.current.getValue(),
            'content': noteContentTextareaRef.current.getValue()
        }
        const response = await createTicketNote(ticketUUID, data)
        if (response.success) {
            const notify = () => toast.success('Notatak została dodana');
            notify()
            navigate(`/admin/tickets/${ticketUUID}`, {
                replace: true
            });
        } else {
            const notify = () => toast.error('Dodanie notatki nie udało się');
            notify()
        }
    }
    return (
        <div>
            <h1 className="font-semibold m-12 ml-20 text-xl">Nowa notatka</h1>
            <Panel>
                <form className="flex flex-col w-full gap-5 " onSubmit={saveNoteHandler} noValidate>
                    <Input
                        inValidMessage={'To pole jest obowiązkowe'}
                        name={'Tytuł'}
                        ref={noteTitleInputRef}
                        isRequired={true}
                        inputValidatingFunc={validateNoteTitle}
                        allowedSigns={allSigns255} />
                    <Textarea
                        name={'Treść'}
                        isRequired={true}
                        inValidMessage={'To pole jest obowiązkowe'}
                        ref={noteContentTextareaRef}
                        inputValidatingFunc={validateNoteDescription}
                        allowedSigns={allSignsText}
                    />
                    <div className="flex flex-row-reverse gap-5">
                        <Button type={'submit'} value={'Utwórz'} width={'w-[10%]'} isActive={true} />
                    </div>
                </form>
            </Panel>
        </div>
    );
}
export default TicketNoteNew